// Container with no padding for navbar
@media screen and (min-width: 1680px) {
  .logo-shift {
    position: relative;
    left: -50px;
  }
}
@media screen and (max-width: 1599px) {
  .logo-shift {
    left: 0px;
  }
}
@media screen and (max-width: 1280px) {
  .logo-shift {
    left: 0;
  }
}
.nav-btn {
  box-sizing: border-box;
  width: 168px;

  background-color: #b0b885;
  display: block;
  min-height: 45px;
  margin-left: 25px;
  border-radius: 30px;
  line-height: 43px;
  text-align: center;

  font-weight: 600;
  color: white !important;
  font-size: 16px;
  display: flex;

  justify-content: center;
  @media screen and (max-width: $tablet) {
    margin: 0 10px 0 0;
    margin-top: 10px;
  }
}

main {
  margin-top: 104px;
  @media screen and (max-width: $tablet) {
    margin-top: 94px;
  }
}
.menu-toggle {
  margin-top: 3px;
  width: 25px;
  display: block;
}
.menu-toggle span {
  width: 100%;
  height: 2px;
  display: block;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #010101;
}

.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
  z-index: 9999;
  box-sizing: border-box;
  background-color: #fff;

  padding: 25px 0;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    padding: 15px 0;
  }
  // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.23);

  // .header__container

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 15px;
  }

  // .header__wr-logo

  &__wr-logo {
    max-height: 100%;
    display: block;
    img {
      max-height: 75px;
      height: 100%;
      @media screen and (max-width: 1024px) {
        max-height: 55px;
      }
    }
  }

  // .header__wr-nav

  &__wr-nav {
  }

  // .header__nav

  &__nav {
  }

  // .header__wr-burger

  &__wr-burger {
    display: none;
    @media screen and (max-width: $tablet) {
      display: flex;
    }
  }
  // .header__wr-btns

  &__wr-btns {
    display: flex;
    @media screen and (max-width: $tablet) {
      justify-content: flex-start;
    }
  }
}
.nav-links {
  padding-left: 30px;
  margin-right: 30px;
  @media screen and (max-width: $tablet) {
    padding-left: 0px;
    margin-right: 0px;
  }
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 992px) {
    display: flex !important;
  }
  @media screen and (max-width: $tablet) {
    display: none;
    position: absolute;
    top: 125px;
    left: 0;
    width: 100%;
    background-color: #ffff;
    padding: 10px 20px 25px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.13);
  }
  @media screen and (max-width: $tablet) {
    top: 75px;
  }
  @media screen and (max-width: $mobile) {
    top: 60px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }
  li {
    font-size: 20px;
    color: #000;
    margin-right: 35px;
    font-weight: 500;
    box-sizing: border-box;
    @media screen and (max-width: $tablet) {
      margin-right: 0;

      width: 100%;
      padding: 0;
    }
    &:last-child {
      margin-right: 0px;
      @media screen and (max-width: $tablet) {
      }
    }
    a {
      text-align: center;

      color: #000;
      @media screen and (max-width: $tablet) {
        padding: 15px 0;
        display: block;
        width: 100%;
        text-align: left;
      }
    }
  }

  // .nav__has-dropdown

  .nav__has-dropdown {
    @media screen and (max-width: $tablet) {
      padding: 0 40px 0 0;
      position: relative;
    }

    // .nav__has-dropdown--active
    &--active {
      &:before {
        transform: rotate(180deg);
        transition: all 250ms ease-out;
      }
    }
    &:before {
      transition: all 250ms ease-out;

      @media screen and (max-width: $tablet) {
        position: absolute;
        content: "";
        right: 10px;
        top: 26px;

        width: 24px;
        height: 12px;
        background: url(../../img/arrow-down.svg) no-repeat center;
        background-size: contain;
      }
    }
    & > a {
      position: relative;
      &:before {
        position: absolute;
        content: "";
        top: 100%;
        left: calc(50% - 50px);
        width: 100px;
        height: 52px;
        opacity: 0;
        visibility: hidden;
      }
      &:after {
        position: absolute;
        z-index: 99;
        content: "";
        top: calc(100% + 48px);
        left: calc(50% - 15px);
        width: 30px;
        height: 16px;
        background: url(../../img/triangle-menu.png) no-repeat center;
        background-size: contain;

        opacity: 0;
        visibility: hidden;
        transition: all 350ms ease-out;
        transform: translateY(-15px);
      }
    }
    &:hover {
      @media (min-width: 992px) {
        & > a {
          &:before {
            opacity: 1;
            visibility: visible;
          }
          &:after {
            opacity: 1;
            visibility: visible;
            transition: all 350ms ease-out;
            transform: translateY(0);
          }
        }
        .nav__dropdown {
          opacity: 1;
          visibility: visible;
          transition: all 350ms ease-out;
          transform: translateY(0);
        }
      }
    }
  }
}

// .nav__dropdown

.nav__dropdown {
  @media (min-width: 992px) {
    transform: translateY(-15px);

    opacity: 0;
    visibility: hidden;
    transition: all 350ms ease-out;
    position: absolute;
    border-top: 1px solid #707070;
    z-index: 88;
    top: 100%;
    left: 0;
    width: 100vw;
    padding: 25px 15px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    display: flex !important;
    justify-content: center;
  }
  @media (max-width: 991px) {
    display: none;
    padding: 20px;
    width: calc(100% + 35px);
  }

  box-sizing: border-box;
  background-color: #ffffff;
  ul {
    @media (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      gap: 20px;
    }
  }
  li {
  }
  a {
    display: block;
    @media (max-width: 991px) {
      padding: 0 !important;
    }
    &:hover {
      path.border-color {
        stroke: #473625;
        transition: all 250ms ease-out;
      }
      path.fill-color {
        fill: #473625;
        transition: all 250ms ease-out;
      }
      p {
        color: #473625;
        transition: all 250ms ease-out;
      }
    }
  }
  path {
    transition: all 250ms ease-out;
  }
  svg {
    max-height: 60px;
    min-height: 60px;
    display: block;
    margin: 0 auto;
    transform: scale(0.9);

    @media (max-width: 991px) {
      min-height: 0;
      transform: scale(0.8);
    }
  }
  p {
    margin-top: 15px;
    color: #a79075;
    @include font-size(20, 18);
    font-weight: 400;
    transition: all 250ms ease-out;
    @media (max-width: 991px) {
      text-align: center;
    }
  }
}

//Animation
.menu-toggle.burger span.top {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
.menu-toggle.burger span.middle {
  width: 100%;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease-out 0.3s;
  -o-transition: all 0.3s ease-out 0.3s;
  transition: all 0.3s ease-out 0.3s;
}
.menu-toggle.burger span.bottom {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
.header__wr-nav.active .menu-toggle span.top {
  -webkit-transform: rotate(45deg) translate(5px, 10px);
  -ms-transform: rotate(45deg) translate(5px, 10px);
  transform: rotate(45deg) translate(5px, 10px);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
.header__wr-nav.active .menu-toggle span.middle {
  width: 0%;
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header__wr-nav.active .menu-toggle span.bottom {
  -webkit-transform: rotate(-45deg) translate(0px, -5px);
  -ms-transform: rotate(-45deg) translate(0px, -5px);
  transform: rotate(-45deg) translate(0px, -5px);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}

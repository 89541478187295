.btn {
    font-family: "Foundry Sans";

    height: 50px;
    width: auto;

    cursor: pointer;
    outline: none;

    padding: 0 30px;
    border-radius: 25px;
    border: 0;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    background-color: #b0b885;

    font-size: 20px;
    font-weight: 700;

    text-transform: uppercase;
    // .btn--small

    &--small {
        height: 40px;
        font-size: 15px;
    }
}

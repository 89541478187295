$container: 1530;
$tablet: 991px;
$tablet-small: 767px;
$mobile: 575px;
$mobile-small: 370px;
$container-fluid: 1780;
.container {
    max-width: #{$container + px};
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
    box-sizing: border-box;
    @media (max-width: 575px){
        padding: 0 20px;
    }
}
.container_fluid{
    max-width: #{$container-fluid + px};
}
.select {
    position: relative;
    cursor: pointer;
    height: 41px;

    font-family: "Foundry Sans";
    font-size: 20px;
    font-weight: 300;

    display: inline-block;
    color: #000;
    border: 0;
    width: 100%;
    box-sizing: border-box;
}
.select-styled {
    overflow: hidden;
    outline: none;
    width: 100%;
    height: 41px;

    padding: 10px 40px 10px 13px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: "Foundry Sans";
    font-size: 20px;
    font-weight: 300;
    transition: all 250ms ease-in;
    white-space: nowrap;
}
.select-dft-val {
    color: #5f5f5f;
}
.select-styled:after {
    position: absolute;
    content: "";
    top: 18px;
    z-index: 9;
    right: 20px;
    width: 14px;
    display: block;
    height: 9px;
    background: url(../../img/arrow-down.svg) center  no-repeat;
    background-size: contain;
    transition: all 250ms ease-in;
}
.select-styled:before {
    position: absolute;
    content: "";
    top: 1px;
    z-index: 9;
    left: auto;
    right: 1px;
    width: 40px;
    display: block;
    height: calc(100% - 2px);
    background: #fff;
    background-size: contain;
    transition: all 250ms ease-in;
    border-radius: 5px;
}
.select-styled:active,
.select-styled.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: #000;
    transition: all 250ms ease-in;

    & ~ .select-options {
        border-color: #000;
        transition: all 250ms ease-in;
    }
    &:after {
        transform: rotate(180deg);
        transition: all 250ms ease-in;
    }
}
.select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #878787;
    border-top: 0;
    background-color: #ffffff;
    box-sizing: border-box;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: all 250ms ease-in;
}
.select-options li {
    margin: 0;
    padding: 10px 13px;
    font-family: "Foundry Sans";
    font-size: 20px;
    font-weight: 300;
    // border-bottom: 1px solid #8d8c8c;

}
.select-options li:first-child {
    display: none;
}
.select--show-first-child li:first-child {
    display: block;
}
.select-options li:last-child {
    border: 0;
}
.select-options li:hover {
    background: rgba(63, 63, 63, 0.16);
}
.select-options li.current{
    background: rgba(63, 63, 63, 0.36);
}
.select-options li[rel="hide"] {
    display: none;
}
select {
    display: none;
}

.search-line {
    padding: 50px 0 60px;
    // @media (min-width: 1220px) {
    //     padding: 50px 0 125px;
    // }
    @media (max-width: 768px) {
        padding: 30px 0 30px;
    }
    // .search-line__container

    &__container {
    }

    // .search-line__body

    &__body {
        display: flex;
        align-items: start;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }

    // .search-line__wr-dropdown

    &__wr-dropdown {
        margin-right: 30px;
        @media (max-width: 768px) {
            width: 100%;
            margin: 0;
        }
    }

    // .search-line__wr-search

    &__wr-search {
        width: 100%;

        @media (max-width: 768px) {
            margin-bottom: 25px;
        }
        input {
            min-height: 54px;
            @media (max-width: 768px) {
                min-height: 50px;
            }
        }
    }
}
.search-dropdown {
    position: relative;

    // .search-dropdown__head

    &__head {
        cursor: pointer;
        position: relative;

        overflow: hidden;
        border: 1px solid #473625;
        background-color: #473625;
        width: 340px;
        padding: 9px 60px 9px 18px;
        box-sizing: border-box;
        min-height: 54px;

        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            width: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            z-index: 2;

            right: 25px;
            top: 21px;

            width: 24px;
            height: 14px;

            background: url(../../img/arrow-down--white.svg) no-repeat center;
            background-size: contain;

            transition: all 250ms ease-out;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 80px;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(71, 54, 37, 1) 20%,
                rgba(71, 54, 37, 1) 100%
            );
        }
        p {
            white-space: nowrap;
            color: #ffffff;
            font-size: 25px;
            font-weight: 400;
        }
        .category-item {
            p {
                color: #fff;
                transition: all 250ms ease-out;
            }
            path {
                fill: #fff;
            }
            &:hover {
                p {
                    color: #fff;
                    transition: all 250ms ease-out;
                }
                path {
                    fill: #fff;
                }
            }
        }
    }

    // .search-dropdown__body

    &__body {
        display: none;
        position: absolute;
        z-index: 99;

        left: 0;
        top: calc(100% + 27px);
        max-width: 1175px;
        width: 100vw;

        @media (max-width: 1220px) {
            max-width: calc(100vw - 30px);
        }
        &:before {
            content: "";
            position: absolute;
            z-index: 2;

            left: 287px;
            top: -18px;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 18px 15px;
            border-color: transparent transparent #f0f0f0 transparent;

            @media (max-width: 768px) {
                left: auto;
                right: 24px;
            }
        }
    }

    // .search-dropdown--active

    &--active {
        .search-dropdown__head {
            &:before {
                transform: rotate(180deg);
                transition: all 250ms ease-out;
            }
        }
    }
}
.search-dropdown-el {
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    display: grid;

    grid-template-columns: repeat(6, auto);
    gap: 20px 50px;
    padding: 25px 30px;

    @media (max-width: 1220px) {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }
    // .search-dropdown-el__item

    &__item {
        p {
            max-width: 175px;
        }
    }
}
.category-item {
    display: flex;
    align-items: center;

    &:hover {
        p {
            color: #a79075;
            transition: all 250ms ease-out;
        }
        path {
            fill: #a79075;
        }
    }
    // .category-item__wr-icon

    &__wr-icon {
        margin-right: 15px;
    }
    path {
        transition: all 250ms ease-out;
    }
    p {
        color: #000000;
        @include font-size(25, 18);
        font-weight: 400;
        transition: all 250ms ease-out;
    }
}

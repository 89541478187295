.media-with-testimonial {
    margin-bottom: 44px;
    @media screen and (max-width: 1200px) {
        margin-bottom: 25px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 0;
    }

    // .media-with-testimonial__container

    &__container {
    }

    // .media-with-testimonial__body

    &__body {
        display: flex;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }

    // .media-with-testimonial__small-slide

    &__small-slide {
        max-width: 450px;
        width: 100%;
        margin-right: 50px;
        @media screen and (max-width: 1200px) {
            margin-right: 25px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }
    }

    // .media-with-testimonial__big-side

    &__big-side {
        max-width: 1000px;
        width: 100%;
    }
}
.media-slide {
    padding: 75px 40px;
    padding-left: 0;
    box-sizing: border-box;
    background-color: #473625;

    position: relative;
    @media screen and (max-width: 1024px) {
        padding: 45px 20px;
    }
    @media screen and (max-width: 575px) {
        padding: 35px 0;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50vw;
        height: 100%;
        background-color: #473625;
        @media screen and (max-width: 1024px) {
            left: -25px;
            right: auto;
            width: 100vw;
        }
        @media screen and (max-width: 575px) {
            left: -20px;
        }
    }
    // .media-slide__inner

    &__inner {
        position: relative;
        z-index: 10;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    h6 {
        position: relative;
        color: #a79075;
        @include font-size(30, 24);
        font-weight: 600;
        line-height: 1.4;
        font-style: italic;

        padding-bottom: 25px;
        margin-bottom: 80px;
        @media screen and (max-width: 1024px) {
            margin-bottom: 40px;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 64px;

            background-color: #a79075;
        }
    }
}
.testimonial-side {
    padding: 70px;
    padding-right: 0;
    box-sizing: border-box;

    background-color: #a79075;
    position: relative;
    @media screen and (max-width: 1024px) {
        padding: 45px 20px;
    }
    @media screen and (max-width: 575px) {
        padding: 35px 0;
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(50vw + 250px);
        height: 100%;

        background-color: #a79075;
        @media screen and (max-width: 1024px) {
            left: -25px;
            right: auto;
            width: 100vw;
        }
        @media screen and (max-width: 575px) {
            left: -20px;
        }
    }
    // .testimonial-side__inner

    &__inner {
        position: relative;
        z-index: 10;
        p {
            font-style: italic;
            color: #ffffff;
            font-family: "Foundry Sans";
            @include font-size(28, 24);
            line-height: 1.4;
            margin-bottom: 24px;
            font-weight: 400;
        }
        h6 {
            color: #473625;
            @include font-size(25, 18);
            font-weight: 600;
            line-height: 1.4;
        }
        a {
            color: #ffffff;
            @include font-size(28, 24);
            font-weight: 700;
            line-height: 1.3;

            padding-left: 30px;

            position: relative;
            &:before{
                position: absolute;
                content: "";
                left: 0;
                top: 8px;
                width: 14px;
                height: 25px;

                background: url(../../img/arrow-get-in-touch.svg) center no-repeat;
                background-size: contain;

                transition: all 250ms ease-out;

            }
            &:hover{
                &:before{
                    transform: translateX(5px);
                    transition: all 250ms ease-out;
                }
            }
        }
    }

    // .testimonial-side__wr-bg-img

    &__wr-bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(50vw + 250px);
        height: 100%;

        background-color: #a79075;
        @media screen and (max-width: 1024px) {
            left: -25px;
            right: auto;
            width: 100vw;
        }
        @media screen and (max-width: 575px) {
            left: -20px;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

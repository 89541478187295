.form {
    @media screen and (max-width: 991px) {
        width: 90%;
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    // .form__submit
    &__submit {
        color: #ffffff;

        font-size: 16px;
        font-weight: 700;

        text-transform: uppercase;
        text-align: center;
        width: 197px;
        height: 45px;
        background-color: #b0b885;
        border: none;
        border-radius: 30px;
        margin-top: 25px;
    }

    // .form__input-item
    &__input-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 575px) {
            width: 100%;
            margin: 0 auto;
            margin-top: 15px;
        }
    }

    // .form__wr-row

    &__wr-row {
        margin-top: 20px;
        &:first-child {
            margin-top: 0px;
        }
        // .form__wr-row--of-2-items
        &--of-2-items {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 575px) {
                display: flex;
                flex-direction: column;
                margin-top: 0px;
            }
            .form__input-item {
                @media screen and (min-width: 576px) {
                    width: 48%;
                }
            }
        }
    }
    // .form__wr-radio-btns

    &__wr-radio-btns {
        margin-top: 9px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        gap: 15px 20px;

        // .form__wr-radio-btns--yes-no

        &--yes-no {
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

            gap: 15px 12px;
        }
    }
}
.input-item {
    // .book-inputs label
    label {
        line-height: 1.2;
        cursor: pointer;
        display: block;
        color: #5f5f5f;

        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        padding-bottom: 10px;
    }
    input,
    textarea {
        font-family: "Foundry Sans";

        -moz-appearance: none;
        -webkit-appearance: none;

        outline: none;
        box-sizing: border-box;
        padding: 0px 10px;
        height: 41px;
        width: 100%;
        border: 1px solid #707070;
        border-radius: 5px;

        @include font-size(20, 15);
        font-weight: 300;
        &::placeholder {
            font-family: "Foundry Sans";
        }
    }
    textarea {
        min-height: 78px;
        padding: 10px;
        box-sizing: border-box;

        width: 100%;
        resize: none;
    }
    // .input-item__wr-time-picker

    &__wr-time-picker {
        display: flex;
        input {
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }
        .select {
            max-width: 95px;
            width: 100%;
        }
        .select-styled {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // .input-item__wr-date-picker

    &__wr-date-picker {
        position: relative;

        label {
            position: absolute;
            content: "";
            right: 11px;
            top: 7px;

            width: 25px;
            height: 25px;

            background-size: contain;
        }
        input {
            padding-right: 40px;
        }
    }

    // .input-item__wr-checkbox

    &__wr-checkbox {
        input {
            display: none;
            &:checked {
                & ~ label {
                    &:after {
                        display: block;
                    }
                }
            }
        }
        label {
            color: #888887;
            @include font-size(20, 18);
            font-weight: 300;
            padding: 5px 0 0 33px;
            box-sizing: border-box;
            position: relative;

            text-transform: unset;
            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 25px;
                height: 25px;

                border: 1px solid #707070;
                background-color: #ffffff;
                border-radius: 2px;
            }
            &:after {
                display: none;
                position: absolute;
                content: "";
                left: 5px;
                top: 4px;
                width: 18px;
                height: 18px;

                background: url(../../img/foursquare-check-in.svg) no-repeat center;
                background-size: contain;
            }
        }
    }
    // .input-item--search

    &--search {
        position: relative;
        input {
            padding-left: 62px;
            @include font-size(25, 20);
        }
    }

    // .input-item__search-btn

    &__search-btn {
        outline: none;
        position: absolute;
        left: 0;
        top: 0;
        border: 0;
        width: 62px;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }
}
input,
textarea {
    font-family: "Foundry Sans";
    &::placeholder {
        font-family: "Foundry Sans";
    }
}

.book-app {
    padding-bottom: 50px;
    background-color: #ffffff;

    .dds {
        max-width: unset;
        justify-content: flex-start;
        max-width: 480px;
        width: 100%;
        @media screen and (max-width: 992px) {
            max-width: 600px;
            margin: 0 auto 20px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }
    }
    .talk-to {
    }
    .book-app-justify {
    }
    .ghh {
        justify-content: space-between;
    }
    .ppp {
        max-width: 741px;
        width: 100%;
        padding-bottom: 0;
        @media screen and (min-width: 991px) {
            padding-left: 40px;
        }
    }
    .getin {
        padding-top: 0px;
    }
    .ad{
        margin-bottom: 0;
    }
}
.book-app2 {
    @media screen and (max-width: 991px) {
        margin-top: 25px;
    }
    .dds {
        max-width: unset;

        min-width: 450px;
        @media screen and (max-width: 991px) {
            min-width: unset;
        }
    }
    .book-app-justify {
    }

    .ppp {
        max-width: 741px;
        width: 100%;
    }
}
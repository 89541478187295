.tabs-section {
    padding: 125px 0 75px;
    @media screen and (max-width: 1024px) {
        padding: 60px 0;
    }
    // .tabs-section__container

    &__container {
        display: grid;
        grid-template-columns: 375px 1fr;
        gap: 80px;
        @media screen and (max-width: #{$container + px}) {
            gap: 40px;
            grid-template-columns: 1fr 3fr;
        }
        @media screen and (max-width: 1024px) {
            grid-template-columns: 100%;
        }
    }

    // .tabs-section__wr-text

    &__wr-text {
        h2 {
            @include font-size(35, 22);
            font-weight: 600;
            line-height: 1.3;
            font-style: italic;
            margin-bottom: 30px;
        }
        p {
            @include font-size(20, 18);
            line-height: 1.3;
            font-weight: 400;
        }
    }

    // .tabs-section__body

    &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;
        @media screen and (max-width: #{$container + px}) {
            gap: 40px;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 100%;
        }
    }

    // .tabs-section__wr-tabs-links

    &__wr-tabs-links {
    }

    // .tabs-section__wr-tabs

    &__wr-tabs {
    }
}
.tabs-links {
    a {
        transition: all 250ms ease-out;
        position: relative;
        display: block;

        color: #000000;

        @include font-size(25, 20);

        line-height: 1.3;
        padding: 9px 60px 9px 0;
        box-sizing: border-box;

        border-bottom: 1px solid #000;

        &:first-child {
            border-top: 1px solid #000;
        }

        &:before {
            position: absolute;
            content: "";
            right: 22px;
            top: calc(50% - 7px);

            width: 24px;
            height: 14px;

            background: url(../../img/arrow-down.svg) center no-repeat;
            background-size: contain;

            transition: all 250ms ease-out;
        }
        &:after {
            position: absolute;
            content: "";

            top: 0;
            right: -80px;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 28px 31px 28px 0;
            border-color: transparent #f3f3f3 transparent transparent;

            opacity: 0;
            visibility: hidden;
            transform: translateX(40px);
            transition: all 450ms ease-out;

            @media screen and (max-width: #{$container + px}) {
                right: -40px;
            }
        }
    }

    // .tabs-links__item--active

    .tabs-links__item--active {
        color: #b0b885;
        transition: all 250ms ease-out;

        &:before {
            top: calc(50% - 12px);
            right: 25px;
            width: 14px;
            height: 24px;
            transition: all 250ms ease-out;
            background-image: url(../../img/arrow-right--green.svg);
        }
        &:after {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: all 350ms ease-out;
        }
    }

    // .tabs-links__item

    &__item {
        &:hover{
            color: #b0b885;
            transition: all 250ms ease-out;
        }
    }
}
.tab-elements {
    background-color: #f3f3f3;

    // .tab-elements__item

    &__item {
        display: none;

        // .tab-elements__item--active

        &--active {
            display: block;
        }
    }
}
.tab-item {
    padding: 40px 40px 30px;
    box-sizing: border-box;

    background-color: #f3f3f3;
    @media screen and (max-width: #{$container + px}) {
        padding: 25px 25px 30px;
    }
    @media screen and (max-width: 768px) {
        position: relative;
        left: -15px;
        width: 100vw;
        padding: 25px 15px 30px;

    }
    h3 {
        color: #000000;

        font-size: 25px;
        font-weight: 600;

        position: relative;

        padding-bottom: 15px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 43px;
            background-color: #000;
        }
    }
    h4 {
        color: #000000;

        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 18px;
    }
    b {
        font-weight: 500;
    }
    p {
        color: #000000;

        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;

        margin-bottom: 18px;
    }
    ul {
        display: grid;
        gap: 12px;
    }
    a:not(.btn) {
        display: flex;
        align-items: center;
        color: #000000;

        font-size: 18px;
        font-weight: 400;
        img {
            margin-right: 14px;
            height: 30px;
            width: 30px;
            object-fit: contain;
        }
    }

    // .tab-item__section

    &__section {
        padding-top: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;
        &:last-child {
            border-bottom: 0;
        }
        // .tab-item__section--row-of-2

        &--row-of-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 26px;
            // .tab-item__section--row-of-2__category-side

            &__category-side {
                &:first-child {
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        right: -12px;
                        height: 100%;
                        width: 1px;
                        background-color: #707070;
                    }
                }
            }
        }

        // .tab-item__section--common-list

        &--common-list {
            ul {
                gap: 0;
            }
            a:not(.btn) {
                display: inline-block;
                color: #000000;

                font-size: 20px;
                font-weight: 500;
                line-height: 1.2;
                position: relative;
                padding-right: 20px;
                box-sizing: border-box;

                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 8px;
                    width: 7px;
                    height: 13px;
                    background: url(../../img/arrow-right--black.svg) center no-repeat;
                    background-size: contain;

                    transition: all 250ms ease-out;
                }
                &:hover {
                    &:before {
                        transform: translateX(5px);
                        transition: all 250ms ease-out;
                    }
                }
            }
        }
    }

    // .tab-item__wr-btns

    &__wr-btns {
        max-width: 220px;
        margin: 0 auto;
        display: grid;
        gap: 12px;
    }
}

.banks {
    background-color: #473625;
    padding: 50px 0px;
}
.banks_h1{
    color: #ffffff;
 
font-size: 60px;
font-weight: 500;
}
.silki-p{
    color: #a79075;
 
font-size: 25px;
font-weight: 400;
padding-bottom: 10px;
}
.banks-name {
    max-width: 50%;
    width: 100%;
}
.silki-block {
    
}
.sil  {
    img {
        padding: 0px 20px;
        &:first-child {
            padding-left: 0px;
        }
        @media screen and (max-width: 575px) {
            padding: 0px 10px;
        }
    }
   
}
.linego {
    width: 1px;
    background-color: #ffffff;
    margin: 0px 50px;
}
.silki {
    height: fit-content;
    margin-left: 5%;
}
.slider-inner {
    div {
        height: 100%;
    }
}
.slider-arrows {
    position: absolute;
    bottom: 20px;
    right: 10px;
    height: fit-content !important;
   
    & >*{
        padding:0 10px;
    }
    img {
        cursor: pointer;
        height: 25px;
        @media screen and (max-width: 575px) {
            height: 15px;
            
        }
    }
}
.our-services-block {
    background-color: rgba(136, 136, 135, 0.16);
    padding: 50px 0px;
    margin-top: 80px;
}
.our-services-img {
    margin-top: -131px;
    max-width: calc(50vw - 30px);
    height: fit-content;
    object-fit: cover;
    object-position: center;
    
    box-sizing: border-box;
}
.our-services {
    margin-left: 0px !important;
    max-width: calc(43vw - 15px) !important;
    margin-right: 7vw !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.slider-img {
    height: 100%;
    object-fit: cover;
}
.slider-block {
    position: relative;
    
}
.slider-wrapper {
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50vw;
}
.slider-inner {
    position: relative;
    height: 100%;
}
.slider-block-wrapper{
    padding: 50px 0px;
    .slider-block{
        min-height: 600px;
        display: flex;
    }
    .our-clinic-template{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.slider .item {
    width: 100%;
    height: 100%;
}
.slider-block-wrapper{
    .slick-slider, .slick-list, .slick-track {
        height: 100% !important;
        
      }
}
  
.our-clinic-template {
    max-width: 45%;
    margin-left: 55%;
    width: 100%;
    .header-our-clinic{
        color: #000000;
         
        font-size: 27px;
        font-weight: 500;
        /* Text style for "Our clinic" */
        text-transform: uppercase;
        
    }
    .black-line {
        margin-top: 25px;
        height: 4px;
        background-color: #000000;
        width: 65px;
        margin-bottom: 35px;
    }
    .text{
        color: #000000;
         
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        padding-top: 20px;
    }
    .feedback {
        box-sizing: border-box;
        color: #ffffff;
         
        font-size: 15px;
        font-weight: 700;
        /* Text style for "Clinic Fee" */
        display: flex;
        justify-content: center;
        
        text-transform: uppercase;
        padding: 10px 40px;
        min-height: 45px;
        background-color: #b0b885;
        width: fit-content;
        border-radius: 30px;
        line-height: 27px;
        margin-top: 35px;
    }
    .h {
        color: #000000;
         
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4;
        padding-bottom: 25px;
        font-style: italic;
    }

}
.map-block {

    padding: 50px 0px;
    .black-line {
        width: 100%;
        height: 1px;
        background-color: black;
    }
    .follow-us{
        img {
            height: 25px;
        }
        >*{
            padding-right: 10px;
            
        }

        >*:first-child{
            padding-right: 30px;
        }
    }
}
.map-img {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    max-width: 50vw;
    height: fit-content;
    object-fit: cover;
    object-position: center;
}
.map-block-inner {
    position: relative;
}
.map-svg{
    padding-right: 30px;
}
.map-block-information-block {
    margin-left: 55%;
    max-width: 400px;
    width: 100%;
    >*{
        margin-top: 30px;
    }
    >*:first-child {
        margin-top: 0px;
    }
}
.our-services-text {
    max-width: 575px;
}
.map-email {
    margin-top: 3px;
}
.map-block-text{
    color: #000000;
 
font-size: 25px;
line-height: 30px;
font-weight: 400;
    span {
        font-weight: 600;
    }
}
@media screen and (max-width: 1530px) {
    .slider-wrapper {
        position: static;
        margin-left: -15px;
       
    }
    .map-img {
        position: static;
        margin-left: -15px;
        
    }
    .map-block-information-block {
        margin-left: 5vw;
    }
    .our-clinic-template {
        margin-left: 5%;
    }
}
@media screen and (max-width: 1280px) {
    .slider-wrapper{
        margin-left: 0px;
        max-width: unset;
        width:  100%;
        margin: 0 auto;
    }
    .our-clinic-template{
        max-width: 100%;
        margin-left: 0px;
        margin: 0 auto;
        margin-top: 30px;
        width: 100%;
    }
    .our-services-img{
        margin-top: 0px;
    }
    .banks-name {
        width: unset;
        max-width: unset;
    }
    .our-services-block {
        margin-top: 0px;
    }
}
@media screen and (max-width: 1024px) {
    
    .map-change {
        flex-direction: column;
    }
    .map-img {
        width: 100%;
        margin: 0 auto;
        max-width: unset;
    }
    .map-block-information-block {
        
        margin: 0 auto;
        margin-top: 30px;
        max-width: unset;
        width: 100%;
    }
    .our-clinic-template{
        max-width: 100%;
    }

}
@media screen and (max-width: 767px) {
    
    .banks_h1 {
        font-size: 60px;
    }
    .silki-p  {
        font-size: 20px;
    }
    .sil img  {
        height: 30px;
    }
    .linego {
        margin: 0px 20px;
    }
    .our-services {
        max-width: unset !important;
        width: 90% !important;
        margin : 0 auto !important;
    }
    .our-services-img {
        max-width: 90%;
        margin : 0 auto;
        margin-top: 30px;
        height: unset;
    }
    
}
@media screen and (max-width: 575px) {
    .map-block {
        padding: 25px 0px;
    }
    .our-services-block{
        padding: 25px 0px;
    }
    .slider-block-wrapper {
        padding: 25px 0px;
    }
    .banks-just{
        justify-content: flex-start;
        align-items: unset;
    }
    .silki {
        margin-left: 0px;
        margin-top: 10px;
    }
    .linego {
        margin: 0px 10px ;
        width: 0px;
    }
    .banks_h1  {
        font-size: 35px;
    }
    .banks {
        padding: 20px 0px;
    }
    .our-clinic-template .h {
        font-size: 25px ;
        line-height: 35px ;
    }
    .map-block-text {
        font-size: 18px;
    }
    
}

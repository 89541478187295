.category-arc-section {
    padding: 40px 0 60px;
    margin-bottom: 50px;
    background-color: rgba(136, 136, 135, 0.16);

    @media (max-width: 1024px) {
        margin-bottom: 20px;
        padding-bottom: 40px;
    }

    // .category-arc-section__container

    &__container {
    }

    // .category-arc-section__head

    &__head {
        h3 {
            position: relative;
            color: #000000;

            font-size: 30px;
            font-weight: 500;

            padding-bottom: 18px;
            &:before {
                position: absolute;
                content: "";
                width: 47px;
                height: 2px;

                left: 0;
                bottom: 0;
                background-color: #000;
            }
        }
    }

    // .category-arc-section__body

    &__body {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;
        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
            gap: 40px;
        }
        @media (max-width: 768px) {
            gap: 20px;
        }
    }

    // .category-arc-section__side

    &__side {
    }
}
.category-archive {
    h4 {
        color: #a79075;

        font-size: 25px;
        font-weight: 400;

        margin-bottom: 16px;
    }

    // .category-archive__wr-items

    &__wr-items {
        border-top: 1px solid #000000;
        padding: 30px 0 0;

        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 25px;

        @media (max-width: 1250px) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
        @media (max-width: 1024px) {
            padding: 30px 25px;
        }
    }

    // .category-archive__item

    &__item {
        display: flex;
        align-items: center;
        img {
            margin-right: 16px;
        }
        p {
            color: #000000;
            @include font-size(25, 22);
            font-weight: 400;
        }
    }
}

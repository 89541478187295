.banner-slider {
    // .banner-slider__item

    &__item {
    }
}
.banner-slider-item {
    position: relative;
    min-height: 56.25vw;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 768px) {
        align-items: flex-end;
        flex-direction: column;
    }

    // .banner-slider-item__wr-bg-img

    &__wr-bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
            position: static;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .banner-slider-item__container

    &__container {
        max-width: 1710px;
        position: relative;
        z-index: 5;
    }

    // .banner-slider-item__wr-text-and-arrow

    &__wr-text-and-arrow {
        @media (min-width: 769px) {
            margin-top: 50px;
        }
        max-width: 650px;
        margin-left: auto;
        @media (max-width: 768px) {
            padding: 20px 20px 35px;
            box-sizing: border-box;
            background-color: #000;
            max-width: 100vw;
            position: relative;
            left: -15px;
            width: 100vw;
        }
        h2 {
             
            font-size: 35px;
            line-height: 1.3;
            font-style: italic;
            color: #fff;
            @media (max-width: 768px) {
                font-size: 28px;
            }
        }
    }

    // .banner-slider-item__wr-arrows

    &__wr-arrows {
        margin-top: 65px;
        display: flex;
        justify-content: space-between;
        max-width: 55px;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    // .banner-slider-item__arrow

    &__arrow {
    }

    // .banner-slider-item__arrow-prev

    &__arrow-prev {
    }

    // .banner-slider-item__arrow-next

    &__arrow-next {
    }
}
.slider-arrow {
    cursor: pointer;
    // .slider-arrow--prev

    &--prev {
    }

    // .slider-arrow--next

    &--next {
        transform: scaleX(-1);
    }

    // .slider-arrow__wr-arrows

    &__wr-arrows {
    }
}

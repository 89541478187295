.spesialist_advice{
    background-color: #473625;
    padding: 50px 0px;
    @media screen and (max-width: $mobile){
        padding: 25px 0px;
     }
}
.spesialist_advice_h1{
    font-family: Foundry Sans;
    font-size: 60px;
    color: #fff;
    font-weight: 500;
}
.line {
    height: 5px;
    width: 60px;
    margin-top: 30px;
    background-color: #fff;
    @media screen and (max-width: $mobile){
        margin-top: 10px;
        margin-bottom: 20px;
     }
}

@font-face {
  font-family: "Foundry Sans"; 
  src: url('../../fonts/fmedium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: "Foundry Sans"; 
  src: url('../../fonts/fnorm.ttf');
  font-weight: normal;
}
@font-face {
  font-family: "Foundry Sans"; 
  font-style: italic;
  font-weight: bold;
  src: url('../../fonts/fdit.ttf');
  
}
@font-face {
  font-family: "Foundry Sans"; 
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/fitm.ttf');
  
}
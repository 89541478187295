.pages{
    padding: 50px 0px;
    .pages-nav{
        *{
            padding: 2px;
        }
        .page {
            color: #000000;
             
            font-size: 20px;
            font-weight: 500;
            /* Text style for "1" */
           
        }
        .nav-img {
            margin-top: 5px;
            height: 15px;
        }
        .nums {
            .num:last-child::after{
                content:'' ;
            }
            .num{
                color: #000000;
                 
                font-size: 20px;
                font-weight: 500;
                &::after{
                    content: '|';
                    padding-left: 5px;
                    color: black;
                }
                
            }
            .active-page {
                color: #b0b885;;
            }
        }
        
        
    }
   
    .pages-list {
        margin-top :50px;
        .page {
            padding: 25px 0px;
            @media screen and (max-width: 575px) {
                flex-direction: column;
            }
        }
        .page:nth-child(even){
            .pages-bg {
                display: block;
            }
        }
    }
    .pages-img {
        height: 50px;
        width: 50px;
    }
    .linezero {
       
        width: 2px;
        background-color: #000000;
        margin : 0px 30px
    }
    .example {
        color: #000000;
         
        font-size: 35px;
        font-weight: 600;
        line-height: 45px;
        padding-bottom: 20px;
        font-style: italic;

    }
    .text{
        color: #000000;
         
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
    }
    .wrapper {
        max-width: 1000px;
    }
    .rewrite {
        position: static;
        padding-top: 15px;
    }
    .pages-bg {
        display: none;
        width: 100vw;
        position: absolute;
        background-color: rgba(136, 136, 135, 0.16);
        top: 0px;
        left: calc( ((-100vw + 100%) / 2));
        height: 100%;
    }
}
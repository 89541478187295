/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

img {
  display: block; }

a {
  text-decoration: none; }

html {
  overflow-x: hidden; }

body {
  overflow-x: hidden; }

img {
  display: block;
  height: auto; }

html,
body {
  font-family: "Foundry Sans"; }

.container {
  max-width: 1530px;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media (max-width: 575px) {
    .container {
      padding: 0 20px; } }

.container_fluid {
  max-width: 1780px; }

@font-face {
  font-family: "Foundry Sans";
  src: url("../../fonts/fmedium.ttf");
  font-weight: 500; }

@font-face {
  font-family: "Foundry Sans";
  src: url("../../fonts/fnorm.ttf");
  font-weight: normal; }

@font-face {
  font-family: "Foundry Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../../fonts/fdit.ttf"); }

@font-face {
  font-family: "Foundry Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/fitm.ttf"); }

.d-end {
  justify-content: flex-end; }

.d-flex {
  display: flex; }

.d-start {
  justify-content: flex-start; }

.d-cent {
  justify-content: center; }

.d-space-b {
  justify-content: space-between; }

.al-center {
  align-items: center; }

.f-col {
  flex-direction: column; }

.h100 {
  height: 100%; }

.p-rel {
  position: relative; }

@media screen and (max-width: 1280px) {
  .f-col1280 {
    flex-direction: column; }
  .f-space-b1280 {
    justify-content: space-between; } }

@media screen and (max-width: 767px) {
  .f-col767 {
    flex-direction: column; } }

@media screen and (max-width: 575px) {
  .f-col575 {
    flex-direction: column; } }

@media screen and (min-width: 1680px) {
  .logo-shift {
    position: relative;
    left: -50px; } }

@media screen and (max-width: 1599px) {
  .logo-shift {
    left: 0px; } }

@media screen and (max-width: 1280px) {
  .logo-shift {
    left: 0; } }

.nav-btn {
  box-sizing: border-box;
  width: 168px;
  background-color: #b0b885;
  display: block;
  min-height: 45px;
  margin-left: 25px;
  border-radius: 30px;
  line-height: 43px;
  text-align: center;
  font-weight: 600;
  color: white !important;
  font-size: 16px;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 991px) {
    .nav-btn {
      margin: 0 10px 0 0;
      margin-top: 10px; } }

main {
  margin-top: 104px; }
  @media screen and (max-width: 991px) {
    main {
      margin-top: 94px; } }

.menu-toggle {
  margin-top: 3px;
  width: 25px;
  display: block; }

.menu-toggle span {
  width: 100%;
  height: 2px;
  display: block;
  margin-bottom: 5px;
  border-radius: 10px;
  background: #010101; }

.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
  z-index: 9999;
  box-sizing: border-box;
  background-color: #fff;
  padding: 25px 0;
  box-sizing: border-box; }
  @media screen and (max-width: 1024px) {
    .header {
      padding: 15px 0; } }
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 15px; }
  .header__wr-logo {
    max-height: 100%;
    display: block; }
    .header__wr-logo img {
      max-height: 75px;
      height: 100%; }
      @media screen and (max-width: 1024px) {
        .header__wr-logo img {
          max-height: 55px; } }
  .header__wr-burger {
    display: none; }
    @media screen and (max-width: 991px) {
      .header__wr-burger {
        display: flex; } }
  .header__wr-btns {
    display: flex; }
    @media screen and (max-width: 991px) {
      .header__wr-btns {
        justify-content: flex-start; } }

.nav-links {
  padding-left: 30px;
  margin-right: 30px; }
  @media screen and (max-width: 991px) {
    .nav-links {
      padding-left: 0px;
      margin-right: 0px; } }

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .nav {
      display: flex !important; } }
  @media screen and (max-width: 991px) {
    .nav {
      display: none;
      position: absolute;
      top: 125px;
      left: 0;
      width: 100%;
      background-color: #ffff;
      padding: 10px 20px 25px;
      box-sizing: border-box;
      box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.13); } }
  @media screen and (max-width: 991px) {
    .nav {
      top: 75px; } }
  @media screen and (max-width: 575px) {
    .nav {
      top: 60px; } }
  .nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .nav ul {
        flex-direction: column; } }
  .nav li {
    font-size: 20px;
    color: #000;
    margin-right: 35px;
    font-weight: 500;
    box-sizing: border-box; }
    @media screen and (max-width: 991px) {
      .nav li {
        margin-right: 0;
        width: 100%;
        padding: 0; } }
    .nav li:last-child {
      margin-right: 0px; }
    .nav li a {
      text-align: center;
      color: #000; }
      @media screen and (max-width: 991px) {
        .nav li a {
          padding: 15px 0;
          display: block;
          width: 100%;
          text-align: left; } }
  @media screen and (max-width: 991px) {
    .nav .nav__has-dropdown {
      padding: 0 40px 0 0;
      position: relative; } }
  .nav .nav__has-dropdown--active:before {
    transform: rotate(180deg);
    transition: all 250ms ease-out; }
  .nav .nav__has-dropdown:before {
    transition: all 250ms ease-out; }
    @media screen and (max-width: 991px) {
      .nav .nav__has-dropdown:before {
        position: absolute;
        content: "";
        right: 10px;
        top: 26px;
        width: 24px;
        height: 12px;
        background: url(../../img/arrow-down.svg) no-repeat center;
        background-size: contain; } }
  .nav .nav__has-dropdown > a {
    position: relative; }
    .nav .nav__has-dropdown > a:before {
      position: absolute;
      content: "";
      top: 100%;
      left: calc(50% - 50px);
      width: 100px;
      height: 52px;
      opacity: 0;
      visibility: hidden; }
    .nav .nav__has-dropdown > a:after {
      position: absolute;
      z-index: 99;
      content: "";
      top: calc(100% + 48px);
      left: calc(50% - 15px);
      width: 30px;
      height: 16px;
      background: url(../../img/triangle-menu.png) no-repeat center;
      background-size: contain;
      opacity: 0;
      visibility: hidden;
      transition: all 350ms ease-out;
      transform: translateY(-15px); }
  @media (min-width: 992px) {
    .nav .nav__has-dropdown:hover > a:before {
      opacity: 1;
      visibility: visible; }
    .nav .nav__has-dropdown:hover > a:after {
      opacity: 1;
      visibility: visible;
      transition: all 350ms ease-out;
      transform: translateY(0); }
    .nav .nav__has-dropdown:hover .nav__dropdown {
      opacity: 1;
      visibility: visible;
      transition: all 350ms ease-out;
      transform: translateY(0); } }

.nav__dropdown {
  box-sizing: border-box;
  background-color: #ffffff; }
  @media (min-width: 992px) {
    .nav__dropdown {
      transform: translateY(-15px);
      opacity: 0;
      visibility: hidden;
      transition: all 350ms ease-out;
      position: absolute;
      border-top: 1px solid #707070;
      z-index: 88;
      top: 100%;
      left: 0;
      width: 100vw;
      padding: 25px 15px 25px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
      display: flex !important;
      justify-content: center; } }
  @media (max-width: 991px) {
    .nav__dropdown {
      display: none;
      padding: 20px;
      width: calc(100% + 35px); } }
  @media (max-width: 991px) {
    .nav__dropdown ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      gap: 20px; } }
  .nav__dropdown a {
    display: block; }
    @media (max-width: 991px) {
      .nav__dropdown a {
        padding: 0 !important; } }
    .nav__dropdown a:hover path.border-color {
      stroke: #473625;
      transition: all 250ms ease-out; }
    .nav__dropdown a:hover path.fill-color {
      fill: #473625;
      transition: all 250ms ease-out; }
    .nav__dropdown a:hover p {
      color: #473625;
      transition: all 250ms ease-out; }
  .nav__dropdown path {
    transition: all 250ms ease-out; }
  .nav__dropdown svg {
    max-height: 60px;
    min-height: 60px;
    display: block;
    margin: 0 auto;
    transform: scale(0.9); }
    @media (max-width: 991px) {
      .nav__dropdown svg {
        min-height: 0;
        transform: scale(0.8); } }
  .nav__dropdown p {
    margin-top: 15px;
    color: #a79075;
    font-weight: 400;
    transition: all 250ms ease-out; }
    @media (min-width: 1530px) {
      .nav__dropdown p {
        font-size: 20px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .nav__dropdown p {
        font-size: calc(18px + 2 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .nav__dropdown p {
        font-size: calc(18px + 3.4 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .nav__dropdown p {
        font-size: 18px; } }
    @media (max-width: 991px) {
      .nav__dropdown p {
        text-align: center; } }

.menu-toggle.burger span.top {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out; }

.menu-toggle.burger span.middle {
  width: 100%;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease-out 0.3s;
  -o-transition: all 0.3s ease-out 0.3s;
  transition: all 0.3s ease-out 0.3s; }

.menu-toggle.burger span.bottom {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out; }

.header__wr-nav.active .menu-toggle span.top {
  -webkit-transform: rotate(45deg) translate(5px, 10px);
  -ms-transform: rotate(45deg) translate(5px, 10px);
  transform: rotate(45deg) translate(5px, 10px);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out; }

.header__wr-nav.active .menu-toggle span.middle {
  width: 0%;
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; }

.header__wr-nav.active .menu-toggle span.bottom {
  -webkit-transform: rotate(-45deg) translate(0px, -5px);
  -ms-transform: rotate(-45deg) translate(0px, -5px);
  transform: rotate(-45deg) translate(0px, -5px);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out; }

.spesialist_advice {
  background-color: #473625;
  padding: 50px 0px; }
  @media screen and (max-width: 575px) {
    .spesialist_advice {
      padding: 25px 0px; } }

.spesialist_advice_h1 {
  font-family: Foundry Sans;
  font-size: 60px;
  color: #fff;
  font-weight: 500; }

.line {
  height: 5px;
  width: 60px;
  margin-top: 30px;
  background-color: #fff; }
  @media screen and (max-width: 575px) {
    .line {
      margin-top: 10px;
      margin-bottom: 20px; } }

.footer {
  padding: 50px 0px;
  background-color: #393939; }
  @media screen and (max-width: 575px) {
    .footer {
      padding: 25px 0px; } }
  .footer .footer-inner {
    padding: 0px 10%; }
    @media screen and (min-width: 1680px) {
      .footer .footer-inner {
        padding: 0px 10%; } }
    @media screen and (max-width: 1280px) {
      .footer .footer-inner {
        padding: 0px 0.5%; } }
  .footer .line-white-horizontal {
    max-width: 1px;
    width: 100%;
    background-color: #fff;
    margin: 0px 30px; }
  .footer .line-white-vertical {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 30px 0px; }
  .footer .contact {
    color: #A79075;
    font-size: 20px;
    font-weight: 600; }
  .footer .name-surname {
    padding-top: 10px; }
    .footer .name-surname input::placeholder {
      color: #ffffff; }
    .footer .name-surname input {
      box-sizing: border-box;
      max-width: 162px;
      width: 100%;
      border: 1px solid #fff;
      padding: 0 10px;
      height: 30px;
      background-color: #393939;
      color: #fff;
      font-size: 18px; }
      @media screen and (max-width: 991px) {
        .footer .name-surname input {
          width: 48%;
          max-width: unset; } }
  .footer .footer-inputs {
    box-sizing: border-box;
    padding: 0 10px;
    border: 1px solid #fff;
    width: 100%;
    max-width: 350px;
    background-color: #393939;
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
    height: 30px;
    resize: none;
    overflow-y: hidden; }
    @media screen and (max-width: 991px) {
      .footer .footer-inputs {
        max-width: unset; } }
    .footer .footer-inputs::placeholder {
      color: #ffffff; }
  .footer textarea.footer-inputs {
    height: 60px;
    padding: 5px 10px; }
  .footer .policy {
    font-family: "Foundry Sans";
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    min-width: 200px; }
    @media screen and (max-width: 991px) {
      .footer .policy {
        min-width: unset;
        font-size: 19px;
        white-space: unset;
        max-width: 300px;
        width: 100%; } }
  .footer .footer-submit-button {
    box-sizing: border-box;
    border: 1.5px solid #fff;
    border-radius: 30px;
    background-color: #393939;
    color: #fff;
    font-size: 13px;
    margin-top: 10px;
    max-width: 100px;
    min-height: 22px;
    width: 100%;
    outline: none;
    padding-top: 3px;
    padding-bottom: 3px; }
  .footer .footer-icons > * {
    margin-right: 15px; }
  @media screen and (max-width: 991px) {
    .footer .links-block {
      flex-direction: unset;
      text-align: center;
      justify-content: space-between; } }
  @media screen and (max-width: 575px) {
    .footer .links-block {
      flex-direction: column;
      text-align: left;
      justify-content: flex-start;
      font-size: 20px; } }
  .footer .footer-form-block {
    width: 100%;
    max-width: 343px; }
    @media screen and (max-width: 991px) {
      .footer .footer-form-block {
        margin: 30px 0px;
        max-width: unset; } }
    @media screen and (max-width: 575px) {
      .footer .footer-form-block {
        margin: 15px 0px; } }
  .footer .footer-email {
    box-sizing: border-box;
    border: 1px solid #fff;
    margin-top: 10px;
    background-color: #393939;
    width: 286px; }
    .footer .footer-email input {
      box-sizing: border-box;
      background-color: #393939;
      color: #fff;
      font-size: 18px;
      border: none;
      padding: 10px 10px;
      width: 100%;
      outline: none;
      height: 30px; }
    .footer .footer-email img {
      display: block;
      height: 12px;
      width: 21px; }
    .footer .footer-email input::placeholder {
      color: #fff; }
  .footer .footer-follow-us {
    padding-top: 30px;
    padding-bottom: 10px; }
  .footer .footer-arrow-submit {
    cursor: pointer;
    background-color: unset;
    border: none;
    outline: none; }
  .footer .footer-designed-by {
    color: #fff;
    font-size: 18px;
    text-align: center; }
    .footer .footer-designed-by a {
      color: #fff;
      font-size: 18px;
      font-weight: 600; }

@media screen and (max-width: 991px) {
  .rrt {
    flex-direction: column; } }

.back-to-overview {
  color: #888887;
  font-size: 25px;
  line-height: 25px;
  font-weight: normal;
  align-content: center;
  border: 1px solid #888887;
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: center;
  min-height: 53px;
  box-sizing: border-box;
  padding: 0px 10px; }
  @media screen and (max-width: 575px) {
    .back-to-overview {
      max-width: 800px; } }

.back-to-overview-block {
  width: 100%;
  max-width: 800px; }
  @media screen and (max-width: 1100px) {
    .back-to-overview-block {
      max-width: 600px; } }
  @media screen and (max-width: 991px) {
    .back-to-overview-block {
      max-width: 500px; } }
  @media screen and (max-width: 767px) {
    .back-to-overview-block {
      max-width: 800px; } }
  @media screen and (max-width: 575px) {
    .back-to-overview-block {
      flex-direction: column; } }

@media screen and (max-width: 767px) {
  .back-to-overview-block-direction {
    flex-direction: column; } }

.next_article {
  border: 1px solid #a79075;
  min-height: 53px;
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
  margin-left: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 10px; }
  .next_article p {
    color: #a79075;
    font-size: 25px;
    font-weight: 400; }
  @media screen and (max-width: 575px) {
    .next_article {
      max-width: 800px;
      margin-top: 25px;
      margin-left: 0px; } }
  @media screen and (max-width: 1100px) {
    .next_article {
      max-width: 600px; } }

div .zzz:last-child {
  padding-right: 0px; }

.serz > * {
  padding: 0px 10px;
  align-self: center; }

.serz a {
  display: flex;
  flex-direction: column;
  justify-content: center; }

@media screen and (max-width: 991px) {
  .serz a {
    font-size: 18px; } }

.back_overview_block {
  padding: 54px 0px; }
  @media screen and (max-width: 575px) {
    .back_overview_block {
      padding: 25px 0px; } }

.share {
  color: #b0b885;
  font-size: 25px;
  font-weight: 500;
  align-self: center;
  width: 160px;
  box-sizing: border-box; }

.news_block {
  padding: 40px 0px;
  background-color: rgba(136, 136, 135, 0.16); }

.image-fit img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center; }
  @media screen and (max-width: 575px) {
    .image-fit img {
      height: 200px; } }

.header-template {
  color: #000000;
  font-size: 35px;
  font-weight: 600;
  font-style: italic; }

.jst {
  padding-top: 30px; }

.contd {
  padding: 0px 50px;
  background-color: #ffffff; }

.normalpp p {
  padding: 10px 0px;
  line-height: 25px;
  color: #000000;
  font-size: 20px; }

.normalpp .med {
  font-weight: 500; }

.tablez {
  padding-top: 25px;
  flex-wrap: wrap; }
  .tablez .blls:nth-child(odd) {
    border-right: 1px solid #000000; }
    .tablez .blls:nth-child(odd) .jojo p {
      color: #000000;
      font-size: 20px;
      padding: 10px 0px;
      line-height: 25px;
      padding-right: 2.5%; }
      .tablez .blls:nth-child(odd) .jojo p span {
        font-weight: 500; }
    .tablez .blls:nth-child(odd) .jojo p:last-child {
      padding-bottom: 30px; }
    .tablez .blls:nth-child(odd) .prevention {
      padding: 10px 0px;
      background-color: #f0f0f0;
      width: 100%;
      border-top: 1px solid #000000;
      border-bottom: 1px solid #000000; }
      .tablez .blls:nth-child(odd) .prevention p.prevvv {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        text-align: center; }
  .tablez .blls:nth-child(even) .jojo p:last-child {
    padding-bottom: 30px; }
  .tablez .blls:nth-child(even) .jojo p {
    color: #000000;
    font-size: 20px;
    padding: 10px 0px;
    line-height: 25px;
    padding-left: 2.5%; }
    .tablez .blls:nth-child(even) .jojo p span {
      font-weight: 500; }
  .tablez .blls:nth-child(even) .prevention {
    padding: 10px 0px;
    background-color: #f0f0f0;
    width: 100%;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000; }
    .tablez .blls:nth-child(even) .prevention p.prevvv {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      text-align: center; }

.blls {
  width: 49.75%;
  border-bottom: 1px solid #000000; }

.wowodu {
  padding-top: 30px;
  padding-bottom: 50px; }
  .wowodu .wowiz {
    display: flex;
    justify-content: end;
    width: 45%;
    height: max-content; }
    .wowodu .wowiz img {
      width: 100%; }
  .wowodu .txtz {
    width: 50%; }
    .wowodu .txtz p.murz {
      font-weight: 600;
      padding-top: 20px; }
    .wowodu .txtz p {
      padding: 10px 0px;
      color: #000000;
      font-size: 20px;
      line-height: 25px;
      font-weight: 400; }
      .wowodu .txtz p:first-child {
        padding-top: 0px; }
  @media screen and (max-width: 991px) {
    .wowodu {
      flex-direction: column; }
      .wowodu .txtz {
        width: 100%;
        margin: 0 auto; }
      .wowodu .wowiz {
        width: 100%;
        padding-top: 25px; } }

.wihiteblock {
  background-color: #ffffff;
  padding: 25px 0px; }

.talk_to {
  margin-top: 100px;
  padding-top: 50px;
  background-color: #eeeeec;
  margin-bottom: 54px; }
  @media screen and (max-width: 991px) {
    .talk_to {
      margin-bottom: 0px;
      padding-bottom: 0px;
      margin-top: 0px; } }

.getin {
  font-family: 'Foundry Sans';
  padding-top: 30px;
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  font-style: italic; }
  @media screen and (max-width: 575px) {
    .getin {
      font-size: 25px;
      line-height: 30px; } }

.dds {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 81px; }

.lor {
  font-size: 20px;
  padding-top: 20px;
  width: 100%;
  line-height: 25px; }

.ad {
  box-sizing: border-box;
  width: 278px;
  background-color: #B0B885;
  font-size: 18px;
  height: 50px;
  margin-top: 22px;
  border-radius: 30px;
  text-align: center;
  font-weight: 700;
  color: white !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 991px) {
    .ad {
      margin-bottom: 30px; } }
  @media screen and (max-width: 575px) {
    .ad {
      width: 200px;
      font-size: 18px; } }

.foc img {
  width: 100%;
  position: relative;
  top: -81px; }

.ppp {
  padding-left: 10%; }

.latest_uploads {
  background-color: #ffffff;
  padding: 40px 0px; }

.uploads_h1 {
  font-family: Foundry Sans;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase; }

.line2 {
  height: 5px;
  width: 50px;
  background-color: #000;
  margin-top: 30px; }

.uploads {
  margin-top: 50px;
  flex-wrap: wrap; }

.bl {
  width: 31%;
  background-color: #faf8f8;
  padding-bottom: 70px;
  position: relative;
  margin-top: 10px; }

.pad {
  padding-left: 38px;
  padding-right: 42px; }
  @media (max-width: 575px) {
    .pad {
      padding: 0 20px; } }

.bl-img {
  height: 50px;
  padding-top: 20px; }

.line3 {
  width: 100%;
  height: 5px;
  background-color: #000; }

.mastitis {
  padding-top: 20px;
  font-family: 'Foundry Sans';
  font-size: 26px;
  padding-bottom: 30px;
  font-weight: bold;
  font-style: italic; }

.mast {
  font-size: 20px;
  font-weight: 300;
  padding-top: 5px;
  white-space: pre-line;
  line-height: 25px; }

.readmoreblock {
  padding-top: 30px;
  position: absolute;
  bottom: 30px;
  left: 38px; }

.readmbut {
  font-weight: bold;
  font-size: 20px;
  color: #B0B885; }

.arrow img {
  position: relative;
  margin-top: 4px;
  width: 10px;
  padding-left: 15px; }

.banks {
  background-color: #473625;
  padding: 50px 0px; }

.banks_h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 500; }

.silki-p {
  color: #a79075;
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 10px; }

.banks-name {
  max-width: 50%;
  width: 100%; }

.sil img {
  padding: 0px 20px; }
  .sil img:first-child {
    padding-left: 0px; }
  @media screen and (max-width: 575px) {
    .sil img {
      padding: 0px 10px; } }

.linego {
  width: 1px;
  background-color: #ffffff;
  margin: 0px 50px; }

.silki {
  height: fit-content;
  margin-left: 5%; }

.slider-inner div {
  height: 100%; }

.slider-arrows {
  position: absolute;
  bottom: 20px;
  right: 10px;
  height: fit-content !important; }
  .slider-arrows > * {
    padding: 0 10px; }
  .slider-arrows img {
    cursor: pointer;
    height: 25px; }
    @media screen and (max-width: 575px) {
      .slider-arrows img {
        height: 15px; } }

.our-services-block {
  background-color: rgba(136, 136, 135, 0.16);
  padding: 50px 0px;
  margin-top: 80px; }

.our-services-img {
  margin-top: -131px;
  max-width: calc(50vw - 30px);
  height: fit-content;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box; }

.our-services {
  margin-left: 0px !important;
  max-width: calc(43vw - 15px) !important;
  margin-right: 7vw !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.slider-img {
  height: 100%;
  object-fit: cover; }

.slider-block {
  position: relative; }

.slider-wrapper {
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50vw; }

.slider-inner {
  position: relative;
  height: 100%; }

.slider-block-wrapper {
  padding: 50px 0px; }
  .slider-block-wrapper .slider-block {
    min-height: 600px;
    display: flex; }
  .slider-block-wrapper .our-clinic-template {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.slider .item {
  width: 100%;
  height: 100%; }

.slider-block-wrapper .slick-slider, .slider-block-wrapper .slick-list, .slider-block-wrapper .slick-track {
  height: 100% !important; }

.our-clinic-template {
  max-width: 45%;
  margin-left: 55%;
  width: 100%; }
  .our-clinic-template .header-our-clinic {
    color: #000000;
    font-size: 27px;
    font-weight: 500;
    /* Text style for "Our clinic" */
    text-transform: uppercase; }
  .our-clinic-template .black-line {
    margin-top: 25px;
    height: 4px;
    background-color: #000000;
    width: 65px;
    margin-bottom: 35px; }
  .our-clinic-template .text {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    padding-top: 20px; }
  .our-clinic-template .feedback {
    box-sizing: border-box;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    /* Text style for "Clinic Fee" */
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    padding: 10px 40px;
    min-height: 45px;
    background-color: #b0b885;
    width: fit-content;
    border-radius: 30px;
    line-height: 27px;
    margin-top: 35px; }
  .our-clinic-template .h {
    color: #000000;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    padding-bottom: 25px;
    font-style: italic; }

.map-block {
  padding: 50px 0px; }
  .map-block .black-line {
    width: 100%;
    height: 1px;
    background-color: black; }
  .map-block .follow-us img {
    height: 25px; }
  .map-block .follow-us > * {
    padding-right: 10px; }
  .map-block .follow-us > *:first-child {
    padding-right: 30px; }

.map-img {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  max-width: 50vw;
  height: fit-content;
  object-fit: cover;
  object-position: center; }

.map-block-inner {
  position: relative; }

.map-svg {
  padding-right: 30px; }

.map-block-information-block {
  margin-left: 55%;
  max-width: 400px;
  width: 100%; }
  .map-block-information-block > * {
    margin-top: 30px; }
  .map-block-information-block > *:first-child {
    margin-top: 0px; }

.our-services-text {
  max-width: 575px; }

.map-email {
  margin-top: 3px; }

.map-block-text {
  color: #000000;
  font-size: 25px;
  line-height: 30px;
  font-weight: 400; }
  .map-block-text span {
    font-weight: 600; }

@media screen and (max-width: 1530px) {
  .slider-wrapper {
    position: static;
    margin-left: -15px; }
  .map-img {
    position: static;
    margin-left: -15px; }
  .map-block-information-block {
    margin-left: 5vw; }
  .our-clinic-template {
    margin-left: 5%; } }

@media screen and (max-width: 1280px) {
  .slider-wrapper {
    margin-left: 0px;
    max-width: unset;
    width: 100%;
    margin: 0 auto; }
  .our-clinic-template {
    max-width: 100%;
    margin-left: 0px;
    margin: 0 auto;
    margin-top: 30px;
    width: 100%; }
  .our-services-img {
    margin-top: 0px; }
  .banks-name {
    width: unset;
    max-width: unset; }
  .our-services-block {
    margin-top: 0px; } }

@media screen and (max-width: 1024px) {
  .map-change {
    flex-direction: column; }
  .map-img {
    width: 100%;
    margin: 0 auto;
    max-width: unset; }
  .map-block-information-block {
    margin: 0 auto;
    margin-top: 30px;
    max-width: unset;
    width: 100%; }
  .our-clinic-template {
    max-width: 100%; } }

@media screen and (max-width: 767px) {
  .banks_h1 {
    font-size: 60px; }
  .silki-p {
    font-size: 20px; }
  .sil img {
    height: 30px; }
  .linego {
    margin: 0px 20px; }
  .our-services {
    max-width: unset !important;
    width: 90% !important;
    margin: 0 auto !important; }
  .our-services-img {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    height: unset; } }

@media screen and (max-width: 575px) {
  .map-block {
    padding: 25px 0px; }
  .our-services-block {
    padding: 25px 0px; }
  .slider-block-wrapper {
    padding: 25px 0px; }
  .banks-just {
    justify-content: flex-start;
    align-items: unset; }
  .silki {
    margin-left: 0px;
    margin-top: 10px; }
  .linego {
    margin: 0px 10px;
    width: 0px; }
  .banks_h1 {
    font-size: 35px; }
  .banks {
    padding: 20px 0px; }
  .our-clinic-template .h {
    font-size: 25px;
    line-height: 35px; }
  .map-block-text {
    font-size: 18px; } }

@media screen and (max-width: 991px) {
  .dds {
    width: 100%;
    margin: 0 auto;
    max-width: unset;
    padding-bottom: 25px; }
  .foc img {
    width: 100%;
    max-width: unset;
    top: 0px;
    margin: 0;
    position: unset;
    padding: 0px; }
  .ppp {
    width: 100%;
    max-width: 500px;
    padding: 0px;
    margin: 0 auto;
    padding-bottom: 50px; }
  .ghh {
    flex-direction: column; }
  .bl {
    margin: 10px auto; } }

@media screen and (max-width: 767px) {
  .uploads {
    flex-direction: column; }
  .bl {
    width: 100%; }
  .spesialist_advice_h1 {
    font-size: 46px; }
  .ghh {
    flex-direction: column; }
  .foc img {
    margin: 0px auto; }
  .ppp {
    width: 100%;
    padding-left: 0px; }
  .dds {
    width: 90%;
    margin: 0 auto; } }

@media screen and (max-width: 575px) {
  .uploads_h1 {
    font-size: 29px; }
  .logo img {
    height: 50px; }
  .spesialist_advice_h1 {
    font-size: 24px; }
  .mud img {
    height: 75px; } }

@media screen and (max-width: 767px) {
  .tablez {
    flex-direction: column; }
  .blls {
    width: 100%; }
  .tablez .blls:nth-child(odd) {
    border-right: 0px solid #000000; }
    .tablez .blls:nth-child(odd) .jojo {
      padding-left: 0px;
      padding-right: 0px; }
  .tablez .blls:nth-child(even) {
    border-right: 0px solid #000000; }
    .tablez .blls:nth-child(even) .jojo {
      padding-left: 0px;
      padding-right: 0px; }
  .serz {
    padding-top: 20px; }
  .spesialist_advice_h1 {
    font-size: 46px; } }

@media screen and (max-width: 575px) {
  .contd {
    padding: 0 20px; }
  .vodo {
    flex-direction: column; }
  .uploads_h1 {
    font-size: 29px; }
  .logo img {
    height: 50px; }
  .spesialist_advice_h1 {
    font-size: 24px; }
  .mud img {
    height: 75px; } }

.pets-block {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: calc(100vh - 125px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column; }
  .pets-block:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: -1; }
  @media screen and (max-width: 1280px) {
    .pets-block {
      min-height: 900px; } }
  @media screen and (max-width: 767px) {
    .pets-block {
      min-height: 700px; } }
  .pets-block .line {
    width: 47%;
    height: 1px;
    margin-top: 0px;
    background-color: #ffffff; }
  .pets-block .pets-h1 {
    color: #ffffff;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10vh; }
    @media (min-width: 1530px) {
      .pets-block .pets-h1 {
        font-size: 120px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .pets-block .pets-h1 {
        font-size: calc(75px + 45 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .pets-block .pets-h1 {
        font-size: calc(75px + 76.5 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .pets-block .pets-h1 {
        font-size: 75px; } }
    @media screen and (max-width: 1280px) {
      .pets-block .pets-h1 {
        padding-top: 200px; } }
    @media screen and (max-width: 767px) {
      .pets-block .pets-h1 {
        padding-top: 100px; } }
    @media screen and (max-width: 575px) {
      .pets-block .pets-h1 {
        padding-top: 75px; } }
  .pets-block .pet-img {
    width: 6%;
    padding: 0px 1%; }
    @media screen and (max-width: 991px) {
      .pets-block .pet-img {
        width: 10%; } }
    @media screen and (max-width: 991px) {
      .pets-block .pet-img {
        width: 100px; } }
  .pets-block .pets-book-today-block {
    padding-top: 10vh;
    width: 100%; }
  .pets-block .pets-text {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    font-style: italic;
    max-width: 1324px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px; }
    @media screen and (max-width: 767px) {
      .pets-block .pets-text {
        font-size: 20px;
        padding-top: 25px;
        line-height: 30px; } }
    @media screen and (max-width: 1500px) {
      .pets-block .pets-text {
        max-width: 90%; } }
    @media screen and (max-width: 767px) {
      .pets-block .pets-text {
        font-size: 20px;
        padding-top: 25px; } }
    @media screen and (max-width: 575px) {
      .pets-block .pets-text {
        font-size: 16px; } }
  .pets-block .pets-book-today {
    display: block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    /* Text style for "Book today" */
    text-transform: uppercase;
    height: 57px;
    border: 3px solid #ffffff;
    width: 278px;
    text-align: center;
    line-height: 50px;
    box-sizing: border-box;
    border-radius: 30px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 60px; }
    @media screen and (max-width: 767px) {
      .pets-block .pets-book-today {
        margin-top: 25px; } }
  .pets-block.clinics-page .pet-img {
    position: relative;
    top: -30px; }
  .pets-block.clinics-page .pets-text {
    padding-bottom: 95px;
    font-weight: 400;
    line-height: 1.4; }
    @media (min-width: 1530px) {
      .pets-block.clinics-page .pets-text {
        font-size: 35px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .pets-block.clinics-page .pets-text {
        font-size: calc(22px + 13 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .pets-block.clinics-page .pets-text {
        font-size: calc(22px + 22.1 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .pets-block.clinics-page .pets-text {
        font-size: 22px; } }
    @media screen and (max-width: 767px) {
      .pets-block.clinics-page .pets-text {
        font-size: 22px;
        padding-bottom: 45px; } }

.pets-our-services {
  background-color: rgba(136, 136, 135, 0.16);
  padding: 50px 0px;
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 100px; }
  @media screen and (max-width: 1800px) {
    .pets-our-services {
      padding-bottom: 50px;
      margin-bottom: 50px; } }
  @media screen and (max-width: 1725px) {
    .pets-our-services {
      padding-bottom: 50px;
      margin-bottom: 0px; } }
  @media screen and (max-width: 1280px) {
    .pets-our-services .changing {
      flex-direction: column; } }
  .pets-our-services .our-clinic-template {
    margin: 0px;
    max-width: 46%; }
    @media screen and (max-width: 1280px) {
      .pets-our-services .our-clinic-template {
        max-width: 100%;
        margin: 0 auto; } }
  .pets-our-services .pets-our-services-img {
    display: block;
    position: absolute;
    right: 0px;
    top: 50px;
    width: calc(50vw - 100px); }
    @media screen and (min-width: 1920px) {
      .pets-our-services .pets-our-services-img {
        height: 100%; } }
    @media screen and (max-width: 1530px) {
      .pets-our-services .pets-our-services-img {
        width: 100%;
        position: relative; } }
    @media screen and (max-width: 1530px) {
      .pets-our-services .pets-our-services-img {
        width: calc(45% + 15px);
        padding-left: calc(10%);
        margin-right: -15px; } }
    @media screen and (max-width: 1280px) {
      .pets-our-services .pets-our-services-img {
        width: 90%;
        height: auto;
        margin: 0 auto;
        padding: 0px;
        padding-top: 50px; } }
    @media screen and (max-width: 1024px) {
      .pets-our-services .pets-our-services-img {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .pets-our-services .pets-our-services-img {
        padding-top: 0; } }
    .pets-our-services .pets-our-services-img:hover .let-us-a {
      transition: all 250ms ease-out;
      transform: translate(10px); }
    .pets-our-services .pets-our-services-img img {
      height: 100%;
      object-position: center;
      object-fit: cover;
      width: 100%; }

.let-us-a {
  transition: all 250ms ease-out; }

.pets-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -9999; }

.why-vet {
  margin-left: calc(50% + 100px); }
  @media screen and (max-width: 1530px) {
    .why-vet {
      margin-left: 5%; }
      .why-vet .slider-wrapper {
        height: unset; } }
  @media screen and (max-width: 1280px) {
    .why-vet {
      margin-left: 0; } }

@media screen and (max-width: 1530px) {
  .slider-wrapper {
    height: unset; } }

.additional-services-block {
  background-color: rgba(136, 136, 135, 0.16);
  padding: 60px 0px 40px;
  margin-bottom: 150px; }
  @media screen and (max-width: 1200px) {
    .additional-services-block {
      margin-bottom: 22px; } }
  @media screen and (max-width: 1024px) {
    .additional-services-block {
      margin-bottom: 0px; } }
  .additional-services-block .change {
    align-items: flex-start; }
    @media screen and (max-width: 991px) {
      .additional-services-block .change {
        flex-direction: column; } }
  .additional-services-block .puppy-classes-block {
    width: 100%;
    margin-bottom: -110px; }
    @media screen and (max-width: 1280px) {
      .additional-services-block .puppy-classes-block {
        margin-bottom: 0; } }
    .additional-services-block .puppy-classes-block img {
      width: 100%; }
  .additional-services-block .our-clinic-template {
    margin: 0px;
    max-width: 700px; }
    @media screen and (max-width: 991px) {
      .additional-services-block .our-clinic-template {
        max-width: 100%;
        margin: 0 auto; } }
  .additional-services-block .puppy-class-text {
    color: #000000;
    font-size: 30px;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      .additional-services-block .puppy-class-text {
        font-size: 20px; } }
    @media screen and (max-width: 575px) {
      .additional-services-block .puppy-class-text {
        font-size: 18px; } }
  .additional-services-block .puppy-class-block {
    padding-top: 10px; }
  .additional-services-block .puppy-class-img img {
    height: 16px;
    margin-top: 7px;
    margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .additional-services-block .puppy-class-img img {
        height: 15px;
        margin-top: 5px;
        margin-left: 2px; } }
    @media screen and (max-width: 575px) {
      .additional-services-block .puppy-class-img img {
        height: 10px;
        margin-top: 5px;
        margin-left: 2px; } }
  .additional-services-block .puppy-blocks {
    position: relative;
    width: 100%; }
  .additional-services-block .puppy-sizing {
    max-width: 440px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 991px) {
      .additional-services-block .puppy-sizing {
        max-width: 600px;
        margin: 40px auto 0; } }

.stay-in-touch-and-pets {
  position: relative;
  margin-bottom: 50px;
  padding: 75px 0px; }
  @media screen and (max-width: 991px) {
    .stay-in-touch-and-pets {
      margin-bottom: 25px;
      padding: 25px 0px; } }
  @media screen and (max-width: 991px) {
    .stay-in-touch-and-pets .change1 {
      flex-direction: column; } }
  .stay-in-touch-and-pets .stay-in-block {
    max-width: 500px;
    width: 100%; }
    @media screen and (max-width: 1280px) {
      .stay-in-touch-and-pets .stay-in-block {
        max-width: 30%; } }
    @media screen and (max-width: 991px) {
      .stay-in-touch-and-pets .stay-in-block {
        position: relative;
        padding: 25px 0px;
        max-width: 100%; } }
    @media screen and (max-width: 991px) {
      .stay-in-touch-and-pets .stay-in-block .adjustment {
        width: 90%;
        margin: 0 auto; } }
  .stay-in-touch-and-pets .line {
    width: 64px;
    height: 1px;
    border: 2px solid #a79075;
    background-color: #a79075; }
  .stay-in-touch-and-pets .stay {
    color: #a79075;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
    font-style: italic; }
  .stay-in-touch-and-pets .icons {
    margin-top: 50px; }
    .stay-in-touch-and-pets .icons > * {
      padding-right: 20px; }
  .stay-in-touch-and-pets .stay-in-touch {
    background-color: #473625;
    width: 30vw;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: -9999; }
    @media screen and (max-width: 1280px) {
      .stay-in-touch-and-pets .stay-in-touch {
        width: calc(30% + 15px); } }
    @media screen and (max-width: 991px) {
      .stay-in-touch-and-pets .stay-in-touch {
        width: 100vw;
        left: -15px; } }
  @media screen and (max-width: 991px) {
    .stay-in-touch-and-pets .pets-add .adjustment {
      width: 90%;
      margin: 0 auto; } }
  @media screen and (max-width: 1280px) {
    .stay-in-touch-and-pets .pets-add {
      margin-left: 5%; } }
  @media screen and (max-width: 991px) {
    .stay-in-touch-and-pets .pets-add {
      position: relative;
      margin-left: 0px;
      padding-left: 0px;
      padding: 25px 0px; } }
  .stay-in-touch-and-pets .pets-add .text {
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    font-style: italic; }
    @media screen and (max-width: 1280px) {
      .stay-in-touch-and-pets .pets-add .text {
        font-size: 20px; } }
  .stay-in-touch-and-pets .pets-add .get-in {
    padding-top: 25px; }
    @media screen and (max-width: 575px) {
      .stay-in-touch-and-pets .pets-add .get-in {
        align-items: unset; } }
    .stay-in-touch-and-pets .pets-add .get-in p {
      color: #ffffff;
      font-size: 30px;
      font-weight: 700;
      line-height: 40px; }
      @media screen and (max-width: 1280px) {
        .stay-in-touch-and-pets .pets-add .get-in p {
          font-size: 20px; } }
      @media screen and (max-width: 767px) {
        .stay-in-touch-and-pets .pets-add .get-in p {
          font-size: 18px; } }
    .stay-in-touch-and-pets .pets-add .get-in img {
      height: 25px;
      margin-top: 5px;
      margin-right: 20px; }
      @media screen and (max-width: 767px) {
        .stay-in-touch-and-pets .pets-add .get-in img {
          height: 18px;
          margin-right: 10px; } }
      @media screen and (max-width: 575px) {
        .stay-in-touch-and-pets .pets-add .get-in img {
          margin-top: 12px; } }
  .stay-in-touch-and-pets .pets-add-bg {
    position: absolute;
    width: 68vw;
    z-index: -999999;
    right: 0px;
    top: 0px;
    height: 100%; }
    @media screen and (max-width: 1280px) {
      .stay-in-touch-and-pets .pets-add-bg {
        width: calc(65% + 15px); } }
    @media screen and (max-width: 991px) {
      .stay-in-touch-and-pets .pets-add-bg {
        right: unset;
        left: -15px;
        width: 100vw; } }
    .stay-in-touch-and-pets .pets-add-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }

.book-app {
  padding-bottom: 50px;
  background-color: #ffffff; }
  .book-app .dds {
    max-width: unset;
    justify-content: flex-start;
    max-width: 480px;
    width: 100%; }
    @media screen and (max-width: 992px) {
      .book-app .dds {
        max-width: 600px;
        margin: 0 auto 20px; } }
    @media screen and (max-width: 768px) {
      .book-app .dds {
        margin-bottom: 0; } }
  .book-app .ghh {
    justify-content: space-between; }
  .book-app .ppp {
    max-width: 741px;
    width: 100%;
    padding-bottom: 0; }
    @media screen and (min-width: 991px) {
      .book-app .ppp {
        padding-left: 40px; } }
  .book-app .getin {
    padding-top: 0px; }
  .book-app .ad {
    margin-bottom: 0; }

@media screen and (max-width: 991px) {
  .book-app2 {
    margin-top: 25px; } }

.book-app2 .dds {
  max-width: unset;
  min-width: 450px; }
  @media screen and (max-width: 991px) {
    .book-app2 .dds {
      min-width: unset; } }

.book-app2 .ppp {
  max-width: 741px;
  width: 100%; }

.pages {
  padding: 50px 0px; }
  .pages .pages-nav * {
    padding: 2px; }
  .pages .pages-nav .page {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    /* Text style for "1" */ }
  .pages .pages-nav .nav-img {
    margin-top: 5px;
    height: 15px; }
  .pages .pages-nav .nums .num:last-child::after {
    content: ''; }
  .pages .pages-nav .nums .num {
    color: #000000;
    font-size: 20px;
    font-weight: 500; }
    .pages .pages-nav .nums .num::after {
      content: '|';
      padding-left: 5px;
      color: black; }
  .pages .pages-nav .nums .active-page {
    color: #b0b885; }
  .pages .pages-list {
    margin-top: 50px; }
    .pages .pages-list .page {
      padding: 25px 0px; }
      @media screen and (max-width: 575px) {
        .pages .pages-list .page {
          flex-direction: column; } }
    .pages .pages-list .page:nth-child(even) .pages-bg {
      display: block; }
  .pages .pages-img {
    height: 50px;
    width: 50px; }
  .pages .linezero {
    width: 2px;
    background-color: #000000;
    margin: 0px 30px; }
  .pages .example {
    color: #000000;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
    padding-bottom: 20px;
    font-style: italic; }
  .pages .text {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px; }
  .pages .wrapper {
    max-width: 1000px; }
  .pages .rewrite {
    position: static;
    padding-top: 15px; }
  .pages .pages-bg {
    display: none;
    width: 100vw;
    position: absolute;
    background-color: rgba(136, 136, 135, 0.16);
    top: 0px;
    left: calc( ((-100vw + 100%) / 2));
    height: 100%; }

.trust-back .pets-add-bg {
  background-color: #a79075; }

.trust-back .trust-name-occup {
  color: #473625;
  font-size: 25px;
  font-weight: 500;
  line-height: 45px;
  padding-top: 30px; }

.trust-back .text {
  padding-right: 20px; }

.trust-back .abs-block {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60vw; }
  @media screen and (max-width: 991px) {
    .trust-back .abs-block {
      position: static;
      width: 100%; } }

.let-us-drive {
  position: absolute;
  bottom: 40px;
  left: 40px; }
  @media screen and (max-width: 1530px) {
    .let-us-drive {
      padding-left: 10vw; } }
  @media screen and (max-width: 1280px) {
    .let-us-drive {
      padding-left: 0px; } }
  @media screen and (max-width: 575px) {
    .let-us-drive {
      bottom: 10px;
      left: 10px; } }

.let-us-text {
  color: #ffffff;
  font-size: 21px;
  font-weight: 700;
  /* Text style for "Let us Dri" */
  text-transform: uppercase;
  max-width: 234px; }
  @media screen and (max-width: 757px) {
    .let-us-text {
      font-size: 18px; } }
  @media screen and (max-width: 575px) {
    .let-us-text {
      font-size: 16px;
      max-width: 175px; } }

.let-us-a img {
  height: 50px; }
  @media screen and (max-width: 757px) {
    .let-us-a img {
      height: 30px !important;
      width: 30px !important; } }

.banner-slider-item {
  position: relative;
  min-height: 56.25vw;
  display: flex !important;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 768px) {
    .banner-slider-item {
      align-items: flex-end;
      flex-direction: column; } }
  .banner-slider-item__wr-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media (max-width: 768px) {
      .banner-slider-item__wr-bg-img {
        position: static; } }
    .banner-slider-item__wr-bg-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .banner-slider-item__container {
    max-width: 1710px;
    position: relative;
    z-index: 5; }
  .banner-slider-item__wr-text-and-arrow {
    max-width: 650px;
    margin-left: auto; }
    @media (min-width: 769px) {
      .banner-slider-item__wr-text-and-arrow {
        margin-top: 50px; } }
    @media (max-width: 768px) {
      .banner-slider-item__wr-text-and-arrow {
        padding: 20px 20px 35px;
        box-sizing: border-box;
        background-color: #000;
        max-width: 100vw;
        position: relative;
        left: -15px;
        width: 100vw; } }
    .banner-slider-item__wr-text-and-arrow h2 {
      font-size: 35px;
      line-height: 1.3;
      font-style: italic;
      color: #fff; }
      @media (max-width: 768px) {
        .banner-slider-item__wr-text-and-arrow h2 {
          font-size: 28px; } }
  .banner-slider-item__wr-arrows {
    margin-top: 65px;
    display: flex;
    justify-content: space-between;
    max-width: 55px; }
    @media (max-width: 768px) {
      .banner-slider-item__wr-arrows {
        margin-top: 20px; } }

.slider-arrow {
  cursor: pointer; }
  .slider-arrow--next {
    transform: scaleX(-1); }

.strange-grid-and-text {
  padding: 85px 0 96px;
  position: relative; }
  @media (max-width: 1024px) {
    .strange-grid-and-text {
      padding: 60px 0; } }
  .strange-grid-and-text__body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1024px) {
      .strange-grid-and-text__body {
        flex-direction: column-reverse; } }
  .strange-grid-and-text__links {
    max-width: 680px;
    width: 100%; }
    @media (max-width: 1360px) and (min-width: 1025px) {
      .strange-grid-and-text__links {
        max-width: 540px; } }
  .strange-grid-and-text .strange-grid-and-text__content {
    max-width: 650px;
    margin-left: 40px;
    width: 100%; }
    @media (max-width: 1024px) {
      .strange-grid-and-text .strange-grid-and-text__content {
        max-width: 90%;
        margin: 0 auto 40px; } }
    @media (max-width: 575px) {
      .strange-grid-and-text .strange-grid-and-text__content {
        max-width: 100%; } }
  @media (min-width: 1650px) {
    .strange-grid-and-text__wr-media {
      position: absolute;
      top: 70px;
      right: 20px; } }
  @media (max-width: 1651px) {
    .strange-grid-and-text__wr-media.media {
      grid-template-columns: repeat(4, auto);
      justify-content: start;
      margin-bottom: 30px; } }
  .strange-grid-and-text__wr-text.our-clinic-template {
    margin-left: 0;
    max-width: 100%;
    width: 100%; }

.strange-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; }
  @media (max-width: 575px) {
    .strange-grid {
      grid-template-columns: 1fr; } }
  .strange-grid__col {
    display: grid;
    gap: 27px; }
  .strange-grid__item {
    position: relative;
    display: flex; }
    .strange-grid__item--1 {
      z-index: 10;
      height: 218px;
      right: -92px; }
      @media (max-width: 1360px) {
        .strange-grid__item--1 {
          right: -40px; } }
      @media (max-width: 575px) {
        .strange-grid__item--1 {
          right: auto;
          height: 61vw; } }
    .strange-grid__item--2 {
      height: 360px; }
      @media (max-width: 575px) {
        .strange-grid__item--2 {
          height: 98vw; } }
    .strange-grid__item--3 {
      height: 540px;
      align-self: flex-end;
      bottom: -20px; }
      @media (max-width: 575px) {
        .strange-grid__item--3 {
          bottom: auto;
          height: 153vw; } }

.link-img-with-text {
  position: relative;
  display: flex;
  align-items: flex-end; }
  .link-img-with-text:before {
    position: absolute;
    z-index: 4;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    background-color: #000000;
    opacity: 0.2;
    transition: all 250ms ease-out; }
  .link-img-with-text:hover .link-img-with-text__wr-text img {
    transition: all 250ms ease-out;
    transform: translate(10px); }
  .link-img-with-text__wr-text {
    padding: 16px;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase; }
    .link-img-with-text__wr-text img {
      margin-left: 15px;
      transition: all 250ms ease-out; }

.wr-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  .wr-bg-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.media {
  display: grid;
  gap: 19px;
  justify-content: center; }
  .media--horizontal {
    grid-template-columns: repeat(4, auto);
    justify-content: flex-start; }
  .media__item {
    display: block;
    margin: 0 auto; }
    .media__item img {
      height: 35px;
      width: auto; }

.category-arc-section {
  padding: 40px 0 60px;
  margin-bottom: 50px;
  background-color: rgba(136, 136, 135, 0.16); }
  @media (max-width: 1024px) {
    .category-arc-section {
      margin-bottom: 20px;
      padding-bottom: 40px; } }
  .category-arc-section__head h3 {
    position: relative;
    color: #000000;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 18px; }
    .category-arc-section__head h3:before {
      position: absolute;
      content: "";
      width: 47px;
      height: 2px;
      left: 0;
      bottom: 0;
      background-color: #000; }
  .category-arc-section__body {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px; }
    @media (max-width: 1024px) {
      .category-arc-section__body {
        grid-template-columns: 1fr;
        gap: 40px; } }
    @media (max-width: 768px) {
      .category-arc-section__body {
        gap: 20px; } }

.category-archive h4 {
  color: #a79075;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 16px; }

.category-archive__wr-items {
  border-top: 1px solid #000000;
  padding: 30px 0 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 25px; }
  @media (max-width: 1250px) {
    .category-archive__wr-items {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); } }
  @media (max-width: 1024px) {
    .category-archive__wr-items {
      padding: 30px 25px; } }

.category-archive__item {
  display: flex;
  align-items: center; }
  .category-archive__item img {
    margin-right: 16px; }
  .category-archive__item p {
    color: #000000;
    font-weight: 400; }
    @media (min-width: 1530px) {
      .category-archive__item p {
        font-size: 25px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .category-archive__item p {
        font-size: calc(22px + 3 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .category-archive__item p {
        font-size: calc(22px + 5.1 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .category-archive__item p {
        font-size: 22px; } }

.tabs-section {
  padding: 125px 0 75px; }
  @media screen and (max-width: 1024px) {
    .tabs-section {
      padding: 60px 0; } }
  .tabs-section__container {
    display: grid;
    grid-template-columns: 375px 1fr;
    gap: 80px; }
    @media screen and (max-width: 1530px) {
      .tabs-section__container {
        gap: 40px;
        grid-template-columns: 1fr 3fr; } }
    @media screen and (max-width: 1024px) {
      .tabs-section__container {
        grid-template-columns: 100%; } }
  .tabs-section__wr-text h2 {
    font-weight: 600;
    line-height: 1.3;
    font-style: italic;
    margin-bottom: 30px; }
    @media (min-width: 1530px) {
      .tabs-section__wr-text h2 {
        font-size: 35px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .tabs-section__wr-text h2 {
        font-size: calc(22px + 13 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .tabs-section__wr-text h2 {
        font-size: calc(22px + 22.1 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .tabs-section__wr-text h2 {
        font-size: 22px; } }
  .tabs-section__wr-text p {
    line-height: 1.3;
    font-weight: 400; }
    @media (min-width: 1530px) {
      .tabs-section__wr-text p {
        font-size: 20px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .tabs-section__wr-text p {
        font-size: calc(18px + 2 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .tabs-section__wr-text p {
        font-size: calc(18px + 3.4 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .tabs-section__wr-text p {
        font-size: 18px; } }
  .tabs-section__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px; }
    @media screen and (max-width: 1530px) {
      .tabs-section__body {
        gap: 40px; } }
    @media screen and (max-width: 768px) {
      .tabs-section__body {
        grid-template-columns: 100%; } }

.tabs-links a {
  transition: all 250ms ease-out;
  position: relative;
  display: block;
  color: #000000;
  line-height: 1.3;
  padding: 9px 60px 9px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #000; }
  @media (min-width: 1530px) {
    .tabs-links a {
      font-size: 25px; } }
  @media (min-width: 767px) and (max-width: 1530px) {
    .tabs-links a {
      font-size: calc(20px + 5 * (100vw  / 1530)); } }
  @media (max-width: 767px) {
    .tabs-links a {
      font-size: calc(20px + 8.5 * ((100vw - 320px) / 1530)); } }
  @media (max-width: 370px) {
    .tabs-links a {
      font-size: 20px; } }
  .tabs-links a:first-child {
    border-top: 1px solid #000; }
  .tabs-links a:before {
    position: absolute;
    content: "";
    right: 22px;
    top: calc(50% - 7px);
    width: 24px;
    height: 14px;
    background: url(../../img/arrow-down.svg) center no-repeat;
    background-size: contain;
    transition: all 250ms ease-out; }
  .tabs-links a:after {
    position: absolute;
    content: "";
    top: 0;
    right: -80px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 28px 31px 28px 0;
    border-color: transparent #f3f3f3 transparent transparent;
    opacity: 0;
    visibility: hidden;
    transform: translateX(40px);
    transition: all 450ms ease-out; }
    @media screen and (max-width: 1530px) {
      .tabs-links a:after {
        right: -40px; } }

.tabs-links .tabs-links__item--active {
  color: #b0b885;
  transition: all 250ms ease-out; }
  .tabs-links .tabs-links__item--active:before {
    top: calc(50% - 12px);
    right: 25px;
    width: 14px;
    height: 24px;
    transition: all 250ms ease-out;
    background-image: url(../../img/arrow-right--green.svg); }
  .tabs-links .tabs-links__item--active:after {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: all 350ms ease-out; }

.tabs-links__item:hover {
  color: #b0b885;
  transition: all 250ms ease-out; }

.tab-elements {
  background-color: #f3f3f3; }
  .tab-elements__item {
    display: none; }
    .tab-elements__item--active {
      display: block; }

.tab-item {
  padding: 40px 40px 30px;
  box-sizing: border-box;
  background-color: #f3f3f3; }
  @media screen and (max-width: 1530px) {
    .tab-item {
      padding: 25px 25px 30px; } }
  @media screen and (max-width: 768px) {
    .tab-item {
      position: relative;
      left: -15px;
      width: 100vw;
      padding: 25px 15px 30px; } }
  .tab-item h3 {
    color: #000000;
    font-size: 25px;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px; }
    .tab-item h3:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 43px;
      background-color: #000; }
  .tab-item h4 {
    color: #000000;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 18px; }
  .tab-item b {
    font-weight: 500; }
  .tab-item p {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 18px; }
  .tab-item ul {
    display: grid;
    gap: 12px; }
  .tab-item a:not(.btn) {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-weight: 400; }
    .tab-item a:not(.btn) img {
      margin-right: 14px;
      height: 30px;
      width: 30px;
      object-fit: contain; }
  .tab-item__section {
    padding-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000; }
    .tab-item__section:last-child {
      border-bottom: 0; }
    .tab-item__section--row-of-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 26px; }
      .tab-item__section--row-of-2__category-side:first-child {
        position: relative; }
        .tab-item__section--row-of-2__category-side:first-child:before {
          position: absolute;
          content: "";
          right: -12px;
          height: 100%;
          width: 1px;
          background-color: #707070; }
    .tab-item__section--common-list ul {
      gap: 0; }
    .tab-item__section--common-list a:not(.btn) {
      display: inline-block;
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      position: relative;
      padding-right: 20px;
      box-sizing: border-box;
      position: relative; }
      .tab-item__section--common-list a:not(.btn):before {
        position: absolute;
        content: "";
        right: 0;
        top: 8px;
        width: 7px;
        height: 13px;
        background: url(../../img/arrow-right--black.svg) center no-repeat;
        background-size: contain;
        transition: all 250ms ease-out; }
      .tab-item__section--common-list a:not(.btn):hover:before {
        transform: translateX(5px);
        transition: all 250ms ease-out; }
  .tab-item__wr-btns {
    max-width: 220px;
    margin: 0 auto;
    display: grid;
    gap: 12px; }

.btn {
  font-family: "Foundry Sans";
  height: 50px;
  width: auto;
  cursor: pointer;
  outline: none;
  padding: 0 30px;
  border-radius: 25px;
  border: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #b0b885;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase; }
  .btn--small {
    height: 40px;
    font-size: 15px; }

.select {
  position: relative;
  cursor: pointer;
  height: 41px;
  font-family: "Foundry Sans";
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  color: #000;
  border: 0;
  width: 100%;
  box-sizing: border-box; }

.select-styled {
  overflow: hidden;
  outline: none;
  width: 100%;
  height: 41px;
  padding: 10px 40px 10px 13px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Foundry Sans";
  font-size: 20px;
  font-weight: 300;
  transition: all 250ms ease-in;
  white-space: nowrap; }

.select-dft-val {
  color: #5f5f5f; }

.select-styled:after {
  position: absolute;
  content: "";
  top: 18px;
  z-index: 9;
  right: 20px;
  width: 14px;
  display: block;
  height: 9px;
  background: url(../../img/arrow-down.svg) center no-repeat;
  background-size: contain;
  transition: all 250ms ease-in; }

.select-styled:before {
  position: absolute;
  content: "";
  top: 1px;
  z-index: 9;
  left: auto;
  right: 1px;
  width: 40px;
  display: block;
  height: calc(100% - 2px);
  background: #fff;
  background-size: contain;
  transition: all 250ms ease-in;
  border-radius: 5px; }

.select-styled:active,
.select-styled.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-color: #000;
  transition: all 250ms ease-in; }
  .select-styled:active ~ .select-options,
  .select-styled.active ~ .select-options {
    border-color: #000;
    transition: all 250ms ease-in; }
  .select-styled:active:after,
  .select-styled.active:after {
    transform: rotate(180deg);
    transition: all 250ms ease-in; }

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #878787;
  border-top: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 250ms ease-in; }

.select-options li {
  margin: 0;
  padding: 10px 13px;
  font-family: "Foundry Sans";
  font-size: 20px;
  font-weight: 300; }

.select-options li:first-child {
  display: none; }

.select--show-first-child li:first-child {
  display: block; }

.select-options li:last-child {
  border: 0; }

.select-options li:hover {
  background: rgba(63, 63, 63, 0.16); }

.select-options li.current {
  background: rgba(63, 63, 63, 0.36); }

.select-options li[rel="hide"] {
  display: none; }

select {
  display: none; }

@media screen and (max-width: 991px) {
  .form {
    width: 90%;
    margin: 0 auto; } }

@media screen and (max-width: 1024px) {
  .form {
    width: 100%; } }

.form__submit {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  width: 197px;
  height: 45px;
  background-color: #b0b885;
  border: none;
  border-radius: 30px;
  margin-top: 25px; }

.form__input-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (max-width: 575px) {
    .form__input-item {
      width: 100%;
      margin: 0 auto;
      margin-top: 15px; } }

.form__wr-row {
  margin-top: 20px; }
  .form__wr-row:first-child {
    margin-top: 0px; }
  .form__wr-row--of-2-items {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 575px) {
      .form__wr-row--of-2-items {
        display: flex;
        flex-direction: column;
        margin-top: 0px; } }
    @media screen and (min-width: 576px) {
      .form__wr-row--of-2-items .form__input-item {
        width: 48%; } }

.form__wr-radio-btns {
  margin-top: 9px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  gap: 15px 20px; }
  .form__wr-radio-btns--yes-no {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 15px 12px; }

.input-item label {
  line-height: 1.2;
  cursor: pointer;
  display: block;
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 10px; }

.input-item input,
.input-item textarea {
  font-family: "Foundry Sans";
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  box-sizing: border-box;
  padding: 0px 10px;
  height: 41px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
  font-weight: 300; }
  @media (min-width: 1530px) {
    .input-item input,
    .input-item textarea {
      font-size: 20px; } }
  @media (min-width: 767px) and (max-width: 1530px) {
    .input-item input,
    .input-item textarea {
      font-size: calc(15px + 5 * (100vw  / 1530)); } }
  @media (max-width: 767px) {
    .input-item input,
    .input-item textarea {
      font-size: calc(15px + 8.5 * ((100vw - 320px) / 1530)); } }
  @media (max-width: 370px) {
    .input-item input,
    .input-item textarea {
      font-size: 15px; } }
  .input-item input::placeholder,
  .input-item textarea::placeholder {
    font-family: "Foundry Sans"; }

.input-item textarea {
  min-height: 78px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  resize: none; }

.input-item__wr-time-picker {
  display: flex; }
  .input-item__wr-time-picker input {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0; }
  .input-item__wr-time-picker .select {
    max-width: 95px;
    width: 100%; }
  .input-item__wr-time-picker .select-styled {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.input-item__wr-date-picker {
  position: relative; }
  .input-item__wr-date-picker label {
    position: absolute;
    content: "";
    right: 11px;
    top: 7px;
    width: 25px;
    height: 25px;
    background-size: contain; }
  .input-item__wr-date-picker input {
    padding-right: 40px; }

.input-item__wr-checkbox input {
  display: none; }
  .input-item__wr-checkbox input:checked ~ label:after {
    display: block; }

.input-item__wr-checkbox label {
  color: #888887;
  font-weight: 300;
  padding: 5px 0 0 33px;
  box-sizing: border-box;
  position: relative;
  text-transform: unset; }
  @media (min-width: 1530px) {
    .input-item__wr-checkbox label {
      font-size: 20px; } }
  @media (min-width: 767px) and (max-width: 1530px) {
    .input-item__wr-checkbox label {
      font-size: calc(18px + 2 * (100vw  / 1530)); } }
  @media (max-width: 767px) {
    .input-item__wr-checkbox label {
      font-size: calc(18px + 3.4 * ((100vw - 320px) / 1530)); } }
  @media (max-width: 370px) {
    .input-item__wr-checkbox label {
      font-size: 18px; } }
  .input-item__wr-checkbox label:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #707070;
    background-color: #ffffff;
    border-radius: 2px; }
  .input-item__wr-checkbox label:after {
    display: none;
    position: absolute;
    content: "";
    left: 5px;
    top: 4px;
    width: 18px;
    height: 18px;
    background: url(../../img/foursquare-check-in.svg) no-repeat center;
    background-size: contain; }

.input-item--search {
  position: relative; }
  .input-item--search input {
    padding-left: 62px; }
    @media (min-width: 1530px) {
      .input-item--search input {
        font-size: 25px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .input-item--search input {
        font-size: calc(20px + 5 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .input-item--search input {
        font-size: calc(20px + 8.5 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .input-item--search input {
        font-size: 20px; } }

.input-item__search-btn {
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  border: 0;
  width: 62px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; }

input,
textarea {
  font-family: "Foundry Sans"; }
  input::placeholder,
  textarea::placeholder {
    font-family: "Foundry Sans"; }

.search-line {
  padding: 50px 0 60px; }
  @media (max-width: 768px) {
    .search-line {
      padding: 30px 0 30px; } }
  .search-line__body {
    display: flex;
    align-items: start; }
    @media (max-width: 768px) {
      .search-line__body {
        flex-direction: column-reverse; } }
  .search-line__wr-dropdown {
    margin-right: 30px; }
    @media (max-width: 768px) {
      .search-line__wr-dropdown {
        width: 100%;
        margin: 0; } }
  .search-line__wr-search {
    width: 100%; }
    @media (max-width: 768px) {
      .search-line__wr-search {
        margin-bottom: 25px; } }
    .search-line__wr-search input {
      min-height: 54px; }
      @media (max-width: 768px) {
        .search-line__wr-search input {
          min-height: 50px; } }

.search-dropdown {
  position: relative; }
  .search-dropdown__head {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: 1px solid #473625;
    background-color: #473625;
    width: 340px;
    padding: 9px 60px 9px 18px;
    box-sizing: border-box;
    min-height: 54px;
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .search-dropdown__head {
        width: 100%; } }
    .search-dropdown__head:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 25px;
      top: 21px;
      width: 24px;
      height: 14px;
      background: url(../../img/arrow-down--white.svg) no-repeat center;
      background-size: contain;
      transition: all 250ms ease-out; }
    .search-dropdown__head:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 80px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #473625 20%, #473625 100%); }
    .search-dropdown__head p {
      white-space: nowrap;
      color: #ffffff;
      font-size: 25px;
      font-weight: 400; }
    .search-dropdown__head .category-item p {
      color: #fff;
      transition: all 250ms ease-out; }
    .search-dropdown__head .category-item path {
      fill: #fff; }
    .search-dropdown__head .category-item:hover p {
      color: #fff;
      transition: all 250ms ease-out; }
    .search-dropdown__head .category-item:hover path {
      fill: #fff; }
  .search-dropdown__body {
    display: none;
    position: absolute;
    z-index: 99;
    left: 0;
    top: calc(100% + 27px);
    max-width: 1175px;
    width: 100vw; }
    @media (max-width: 1220px) {
      .search-dropdown__body {
        max-width: calc(100vw - 30px); } }
    .search-dropdown__body:before {
      content: "";
      position: absolute;
      z-index: 2;
      left: 287px;
      top: -18px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 18px 15px;
      border-color: transparent transparent #f0f0f0 transparent; }
      @media (max-width: 768px) {
        .search-dropdown__body:before {
          left: auto;
          right: 24px; } }
  .search-dropdown--active .search-dropdown__head:before {
    transform: rotate(180deg);
    transition: all 250ms ease-out; }

.search-dropdown-el {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 20px 50px;
  padding: 25px 30px; }
  @media (max-width: 1220px) {
    .search-dropdown-el {
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr)); } }
  .search-dropdown-el__item p {
    max-width: 175px; }

.category-item {
  display: flex;
  align-items: center; }
  .category-item:hover p {
    color: #a79075;
    transition: all 250ms ease-out; }
  .category-item:hover path {
    fill: #a79075; }
  .category-item__wr-icon {
    margin-right: 15px; }
  .category-item path {
    transition: all 250ms ease-out; }
  .category-item p {
    color: #000000;
    font-weight: 400;
    transition: all 250ms ease-out; }
    @media (min-width: 1530px) {
      .category-item p {
        font-size: 25px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .category-item p {
        font-size: calc(18px + 7 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .category-item p {
        font-size: calc(18px + 11.9 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .category-item p {
        font-size: 18px; } }

.media-with-testimonial {
  margin-bottom: 44px; }
  @media screen and (max-width: 1200px) {
    .media-with-testimonial {
      margin-bottom: 25px; } }
  @media screen and (max-width: 1024px) {
    .media-with-testimonial {
      margin-bottom: 0; } }
  .media-with-testimonial__body {
    display: flex; }
    @media screen and (max-width: 1024px) {
      .media-with-testimonial__body {
        flex-direction: column; } }
  .media-with-testimonial__small-slide {
    max-width: 450px;
    width: 100%;
    margin-right: 50px; }
    @media screen and (max-width: 1200px) {
      .media-with-testimonial__small-slide {
        margin-right: 25px; } }
    @media screen and (max-width: 1024px) {
      .media-with-testimonial__small-slide {
        max-width: 100%; } }
  .media-with-testimonial__big-side {
    max-width: 1000px;
    width: 100%; }

.media-slide {
  padding: 75px 40px;
  padding-left: 0;
  box-sizing: border-box;
  background-color: #473625;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .media-slide {
      padding: 45px 20px; } }
  @media screen and (max-width: 575px) {
    .media-slide {
      padding: 35px 0; } }
  .media-slide::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
    background-color: #473625; }
    @media screen and (max-width: 1024px) {
      .media-slide::before {
        left: -25px;
        right: auto;
        width: 100vw; } }
    @media screen and (max-width: 575px) {
      .media-slide::before {
        left: -20px; } }
  .media-slide__inner {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .media-slide h6 {
    position: relative;
    color: #a79075;
    font-weight: 600;
    line-height: 1.4;
    font-style: italic;
    padding-bottom: 25px;
    margin-bottom: 80px; }
    @media (min-width: 1530px) {
      .media-slide h6 {
        font-size: 30px; } }
    @media (min-width: 767px) and (max-width: 1530px) {
      .media-slide h6 {
        font-size: calc(24px + 6 * (100vw  / 1530)); } }
    @media (max-width: 767px) {
      .media-slide h6 {
        font-size: calc(24px + 10.2 * ((100vw - 320px) / 1530)); } }
    @media (max-width: 370px) {
      .media-slide h6 {
        font-size: 24px; } }
    @media screen and (max-width: 1024px) {
      .media-slide h6 {
        margin-bottom: 40px; } }
    .media-slide h6:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 64px;
      background-color: #a79075; }

.testimonial-side {
  padding: 70px;
  padding-right: 0;
  box-sizing: border-box;
  background-color: #a79075;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .testimonial-side {
      padding: 45px 20px; } }
  @media screen and (max-width: 575px) {
    .testimonial-side {
      padding: 35px 0; } }
  .testimonial-side:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50vw + 250px);
    height: 100%;
    background-color: #a79075; }
    @media screen and (max-width: 1024px) {
      .testimonial-side:before {
        left: -25px;
        right: auto;
        width: 100vw; } }
    @media screen and (max-width: 575px) {
      .testimonial-side:before {
        left: -20px; } }
  .testimonial-side__inner {
    position: relative;
    z-index: 10; }
    .testimonial-side__inner p {
      font-style: italic;
      color: #ffffff;
      font-family: "Foundry Sans";
      line-height: 1.4;
      margin-bottom: 24px;
      font-weight: 400; }
      @media (min-width: 1530px) {
        .testimonial-side__inner p {
          font-size: 28px; } }
      @media (min-width: 767px) and (max-width: 1530px) {
        .testimonial-side__inner p {
          font-size: calc(24px + 4 * (100vw  / 1530)); } }
      @media (max-width: 767px) {
        .testimonial-side__inner p {
          font-size: calc(24px + 6.8 * ((100vw - 320px) / 1530)); } }
      @media (max-width: 370px) {
        .testimonial-side__inner p {
          font-size: 24px; } }
    .testimonial-side__inner h6 {
      color: #473625;
      font-weight: 600;
      line-height: 1.4; }
      @media (min-width: 1530px) {
        .testimonial-side__inner h6 {
          font-size: 25px; } }
      @media (min-width: 767px) and (max-width: 1530px) {
        .testimonial-side__inner h6 {
          font-size: calc(18px + 7 * (100vw  / 1530)); } }
      @media (max-width: 767px) {
        .testimonial-side__inner h6 {
          font-size: calc(18px + 11.9 * ((100vw - 320px) / 1530)); } }
      @media (max-width: 370px) {
        .testimonial-side__inner h6 {
          font-size: 18px; } }
    .testimonial-side__inner a {
      color: #ffffff;
      font-weight: 700;
      line-height: 1.3;
      padding-left: 30px;
      position: relative; }
      @media (min-width: 1530px) {
        .testimonial-side__inner a {
          font-size: 28px; } }
      @media (min-width: 767px) and (max-width: 1530px) {
        .testimonial-side__inner a {
          font-size: calc(24px + 4 * (100vw  / 1530)); } }
      @media (max-width: 767px) {
        .testimonial-side__inner a {
          font-size: calc(24px + 6.8 * ((100vw - 320px) / 1530)); } }
      @media (max-width: 370px) {
        .testimonial-side__inner a {
          font-size: 24px; } }
      .testimonial-side__inner a:before {
        position: absolute;
        content: "";
        left: 0;
        top: 8px;
        width: 14px;
        height: 25px;
        background: url(../../img/arrow-get-in-touch.svg) center no-repeat;
        background-size: contain;
        transition: all 250ms ease-out; }
      .testimonial-side__inner a:hover:before {
        transform: translateX(5px);
        transition: all 250ms ease-out; }
  .testimonial-side__wr-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50vw + 250px);
    height: 100%;
    background-color: #a79075; }
    @media screen and (max-width: 1024px) {
      .testimonial-side__wr-bg-img {
        left: -25px;
        right: auto;
        width: 100vw; } }
    @media screen and (max-width: 575px) {
      .testimonial-side__wr-bg-img {
        left: -20px; } }
    .testimonial-side__wr-bg-img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.strange-grid-and-text {
    padding: 85px 0 96px;
    position: relative;
    @media (max-width: 1024px) {
        padding: 60px 0;
    }
    // .strange-grid-and-text__container

    &__container {
    }

    // .strange-grid-and-text__body

    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1024px) {
            flex-direction: column-reverse;
        }
    }

    // .strange-grid-and-text__links

    &__links {
        max-width: 680px;
        width: 100%;
        @media (max-width: 1360px) and (min-width: 1025px) {
            max-width: 540px;
        }
        @media (max-width: 1024px) {
        }
    }

    // .strange-grid-and-text__content

    .strange-grid-and-text__content {
        max-width: 650px;
        margin-left: 40px;
        width: 100%;
        @media (max-width: 1024px) {
            max-width: 90%;
            margin: 0 auto 40px;
        }
        @media (max-width: 575px) {
            max-width: 100%;
        }
    }

    // .strange-grid-and-text__wr-media

    &__wr-media {
        @media (min-width: 1650px) {
            position: absolute;
            top: 70px;
            right: 20px;
        }
        &.media {
            @media (max-width: 1651px) {
                grid-template-columns: repeat(4, auto);
                justify-content: start;
                margin-bottom: 30px;
            }
        }
    }

    // .strange-grid-and-text__wr-text

    &__wr-text {
        &.our-clinic-template {
            margin-left: 0;
            max-width: 100%;
            width: 100%;
        }
    }
}
.strange-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media (max-width: 575px) {
        grid-template-columns: 1fr;
    }
    // .strange-grid__col

    &__col {
        display: grid;
        gap: 27px;
    }

    // .strange-grid__item

    &__item {
        position: relative;
        display: flex;
        // .strange-grid__item--1

        &--1 {
            z-index: 10;
            height: 218px;
            right: -92px;
            @media (max-width: 1360px) {
                right: -40px;
            }
            @media (max-width: 575px) {
                right: auto;
                height: 61vw;
            }
        }

        // .strange-grid__item--2

        &--2 {
            height: 360px;
            @media (max-width: 575px) {
                height: 98vw;
            }
        }

        // .strange-grid__item--3

        &--3 {
            height: 540px;
            align-self: flex-end;

            bottom: -20px;
            @media (max-width: 575px) {
                bottom: auto;
                height: 153vw;
            }
        }
    }
}
.link-img-with-text {
    position: relative;

    display: flex;
    align-items: flex-end;

    &:before {
        position: absolute;
        z-index: 4;
        content: "";
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border: 1px solid #707070;
        background-color: #000000;
        opacity: 0.2;

        transition: all 250ms ease-out;
    }

    &:hover {
        .link-img-with-text__wr-text {
            img {
                transition: all 250ms ease-out;
                transform: translate(10px);
            }
        }
    }
    // .link-img-with-text__wr-bg-img

    &__wr-bg-img {
    }

    // .link-img-with-text__wr-text

    &__wr-text {
        padding: 16px;
        position: relative;
        z-index: 5;

        display: flex;
        align-items: center;
        color: #ffffff;
         
        font-size: 18px;
        font-weight: 600;

        text-transform: uppercase;
        img {
            margin-left: 15px;
            transition: all 250ms ease-out;
        }
    }
}
.wr-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.media {
    display: grid;
    gap: 19px;
    justify-content: center;

    // .media--horizontal
    &--horizontal{
        grid-template-columns: repeat(4, auto);
        justify-content: flex-start;
    }
    // .media__item

    &__item {
        display: block;
        margin: 0 auto;
        img{
            height: 35px;
            width: auto;
        }
    }
}
